import { useTranslation } from '@dagens/frontend-i18n';
import { OnboardingHistoryKeys } from '../../../types/Producer';
import { ProducerProduct } from '../../../types/Product';
import ProductGroup from './ProductGroup';
import Theme from '@carrot-deprecated/theme';
import { ButtonLink } from '_common/components/button/Button.style';
import InContextOnboardingMessage from '_common/components/in-context-onboarding-message/InContextOnboardingMessage';
import PageHeader from '@components/page-header/PageHeader';
import MultiProductSeasonCalendar from '_common/components/season/MultiProductSeasonCalendar';
import Icon from '_common/components/utils/Icon';
import { useAppSelector } from '_common/hooks/reduxHooks';
import usePageView from '_common/hooks/usePageView';
import { splitProductsBySaleStatus } from '_producer/reducers/products';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';
import { RootState } from 'store';
import { byClosestDate } from 'utils/array';
import { MY_PRODUCTS_PAGE_VIEW } from 'utils/mixpanel';
import REQ, { combineReqs } from 'utils/REQ';

type Props = {
  activeProducts?: ProducerProduct[];
  forSale?: ProducerProduct[];
  onRequest?: ProducerProduct[];
  notForSale?: ProducerProduct[];
};

const ProducerProductsPage = ({
  activeProducts,
  forSale,
  onRequest,
  notForSale
}: Props) => {
  const { t } = useTranslation();
  const byClosestToToday = byClosestDate(new Date());
  usePageView(MY_PRODUCTS_PAGE_VIEW);

  const { hasAddedFirstProduct } = useAppSelector(({ auth }: RootState) => {
    return {
      hasAddedFirstProduct: Boolean(
        auth.onboardingHistory?.[OnboardingHistoryKeys.addedFirstProduct]
      )
    };
  });

  return (
    <Page header={<CommonNavbar showLogo />}>
      <PageHeader
        headerText={t('producer:MyProducts')}
        subTitle={t('producer:productHeaderNumbProducts', {
          count: activeProducts?.length ?? 0
        })}
      />
      <InContextOnboardingMessage
        message={t('producer:products.addYourFirstProduct')}
        showStep={!hasAddedFirstProduct}
      />

      {!hasAddedFirstProduct ? (
        <ButtonLink variant="primary" to="/my-products/add">
          <Icon icon="add" $size="s" mr={Theme.Spacings.xxs} />{' '}
          {t('producer:productAddProduct')}
        </ButtonLink>
      ) : (
        <ButtonLink variant="secondary" to="/my-products/add">
          <Icon icon="add" $size="s" fill="white" mr={Theme.Spacings.xxs} />{' '}
          {t('producer:productAddNewProduct')}
        </ButtonLink>
      )}

      {activeProducts && activeProducts.length > 0 && (
        <MultiProductSeasonCalendar
          products={activeProducts?.sort(byClosestToToday)}
          isProducer
        />
      )}
      {forSale && forSale.length > 0 && (
        <ProductGroup header={t('common:FOR_SALE')} products={forSale} />
      )}

      {onRequest && onRequest.length > 0 && (
        <ProductGroup header={t('common:ON_REQUEST')} products={onRequest} />
      )}

      {notForSale && notForSale.length > 0 && (
        <ProductGroup header={t('common:NOT_FOR_SALE')} products={notForSale} />
      )}
    </Page>
  );
};

const ProducerProductsPageFetcher = () => {
  const { req, activeProducts, forSale, notForSale, onRequest } =
    useAppSelector(
      ({
        producerProducts: { items = [], req: productsReq },
        consumers: { req: consumersReq }
      }) => {
        const { forSale, onRequest, notForSale, activeProducts } =
          splitProductsBySaleStatus(items);

        return {
          req: combineReqs([productsReq, consumersReq]),
          activeProducts,
          forSale,
          onRequest,
          notForSale
        };
      }
    );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <ProducerProductsPage
      activeProducts={activeProducts}
      forSale={forSale}
      onRequest={onRequest}
      notForSale={notForSale}
    />
  );
};

export default ProducerProductsPageFetcher;
