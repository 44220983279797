import { useTranslation } from '@dagens/frontend-i18n';
import { ChangeEvent, Reducer, useReducer } from 'react';
import { Navigate } from 'react-router-dom';
import Callout from '@carrot-deprecated/callout';
import Theme from '@carrot-deprecated/theme';
import FormField from '_common/components/input/FormField';
import SignupRequestButton from '_common/components/signup/SignupRequestButton';
import usePageView from '_common/hooks/usePageView';
import { Page } from '@components/page';
import { createUser } from 'api';
import { USER_SIGNUP_PAGE_VIEW } from 'utils/mixpanel';
import REQ, { ReqType } from 'utils/REQ';
import {
  validateEmail,
  validatePassword,
  validatePasswordRepeat,
  validateSignup
} from 'utils/validation';

type SignupField = 'password' | 'passwordRepeat' | 'email' | 'source';

type SignupAction =
  | { type: 'update'; payload: { field: SignupField; value: string } }
  | { type: 'validationError'; payload: { field: SignupField; value: string } }
  | { type: 'updateReq'; payload: ReqType };

function signupReducer(state: SignupState, action: SignupAction) {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
        req: REQ.INIT,
        validationErrorField: '',
        validationErrorMessage: ''
      };
    case 'validationError':
      return {
        ...state,
        validationErrorField: action.payload.field,
        validationErrorMessage: action.payload.value
      };
    case 'updateReq':
      return { ...state, req: action.payload };
    default:
      throw new Error('Cannot call dispatch without an action');
  }
}

type SignupState = {
  email: string;
  password: string;
  passwordRepeat: string;
  source: string;
  validationErrorField: string;
  validationErrorMessage: string;
  req: ReqType;
};

const initialState: SignupState = {
  email: '',
  password: '',
  passwordRepeat: '',
  source: '',
  validationErrorField: '',
  validationErrorMessage: '',
  req: REQ.INIT
};

const Signup = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer<Reducer<SignupState, SignupAction>>(
    signupReducer,
    initialState
  );

  usePageView(USER_SIGNUP_PAGE_VIEW);

  const validate = () => {
    const validationError = validateSignup(state);

    if (typeof validationError !== 'boolean' && validationError.message) {
      dispatch({
        type: 'validationError',
        payload: {
          field: validationError.field,
          value: validationError.message
        }
      });
      return false;
    }
    return true;
  };

  const onChange = ({
    target: { value, name }
  }: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'update',
      payload: { field: name as SignupField, value }
    });
  };

  const onSubmit = () => {
    if (!validate()) return;

    const { email, password, source } = state;
    dispatch({ type: 'updateReq', payload: REQ.PENDING });

    createUser({ email: email.trim(), password, source })
      .then(() => {
        dispatch({ type: 'updateReq', payload: REQ.SUCCESS });
      })
      .catch(() => {
        dispatch({ type: 'updateReq', payload: REQ.ERROR });
      });
  };

  const {
    email,
    password,
    passwordRepeat,
    source,
    validationErrorField,
    validationErrorMessage,
    req
  } = state;

  if (req === REQ.SUCCESS) {
    return <Navigate to="/login?calloutType=connectAccount" />;
  }

  return (
    <Page>
      <Callout>{t('signup:signupPageHeader')}</Callout>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
        style={{ marginTop: Theme.Spacings.l }}
      >
        <FormField
          labelText={t('signup:emailLabelText')}
          type="text"
          placeholder={t('signup:emailSignupPlaceholder')}
          success={validateEmail(email)}
          warning={Boolean(email && !validateEmail(email))}
          error={validationErrorField === 'email'}
          name="email"
          value={email}
          onChange={onChange}
          autoComplete="email"
        />
        <FormField
          labelText={t('signup:passwordLabelText')}
          type="password"
          placeholder={t('signup:passwordPlaceholder')}
          success={validatePassword(password)}
          warning={Boolean(password && !validatePassword(password))}
          error={validationErrorField === 'password'}
          name="password"
          value={password}
          onChange={onChange}
          autoComplete="new-password"
        />
        <FormField
          labelText={t('signup:passwordRepeatLabelText')}
          type="password"
          placeholder={t('signup:passwordRepeatSignupPlaceholder')}
          success={Boolean(
            passwordRepeat && validatePasswordRepeat(password, passwordRepeat)
          )}
          warning={Boolean(
            passwordRepeat && !validatePasswordRepeat(password, passwordRepeat)
          )}
          error={validationErrorField === 'passwordRepeat'}
          name="passwordRepeat"
          value={passwordRepeat}
          onChange={onChange}
          autoComplete="new-password"
        />
        <FormField
          labelText={t('signup:signupWorkLabelText')}
          type="text"
          placeholder={t('signup:signupworkPlaceholder')}
          value={source}
          onChange={onChange}
          name="source"
        />
        <SignupRequestButton
          req={req}
          onSubmit={onSubmit}
          validationErrorMessage={validationErrorMessage}
        />
      </form>
    </Page>
  );
};

export default Signup;
