import { useTranslation } from '@dagens/frontend-i18n';
import { AvailableLanguages } from '@dagensmat/core';
import React from 'react';
import EmailNotifications from './EmailNotifications';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import { ButtonLink } from '_common/components/button/Button.style';
import DeferChangeHOC from '_common/components/defer-change-hoc/DeferChangeHOC';
import { EditUsers } from '_common/components/edit-users';
import InputWithLabel from '_common/components/input/InputWithLabel';
import { NotificationSettings } from '_common/components/notification-settings';
import SelectWithLabel from '_common/components/select/SelectWithLabel';
import DagensPhoneLink from '_common/components/text-link/DagensPhoneLink';
import Icon from '_common/components/utils/Icon';
import EditDeliveryInformationRedirector from '_common/pages/deliveryInformation/EditDeliveryInformationRedirector';
import ProfileDataExport from '_common/pages/profile/DataExport';
import { DeliveryWindow } from 'types/Consumer';
import { isNotEmpty } from 'utils/validation';

const DeferredInputWithLabel = DeferChangeHOC(InputWithLabel);

type ConsumerProfileFieldsProps = {
  name?: string;
  contactPerson?: string;
  phone?: string;
  deliveryAddress?: string;
  deliveryInfo?: string;
  deliveryWindow?: DeliveryWindow;
  onFieldsChange: (fields: Record<string, any>) => Promise<any>;
  unsubscribeFavoriteNotification: boolean;
  unsubscribeWeeklyReminderNotification: boolean;
  roleLang?: AvailableLanguages;
  isGuest: boolean;
};

const ConsumerProfileFields = ({
  name,
  contactPerson,
  phone,
  deliveryAddress,
  deliveryInfo,
  deliveryWindow,
  onFieldsChange,
  unsubscribeFavoriteNotification,
  unsubscribeWeeklyReminderNotification,
  roleLang,
  isGuest
}: ConsumerProfileFieldsProps) => {
  const { t, i18n } = useTranslation();
  const [saved, setSaved] = React.useState(false);

  return (
    <>
      <DeferredInputWithLabel
        labelText={t('consumer:BusinessName')}
        placeholder={t('consumer:YourName')}
        value={name}
        onChange={value => {
          onFieldsChange({ name: value });
        }}
        validationFunction={isNotEmpty}
        disabled={isGuest}
      />
      <Container mt="jumbo">
        <Typography variant="secondaryHeading">
          {t('common:ContactInformation')}
        </Typography>
        <DeferredInputWithLabel
          labelText={t('consumer:ContactPerson')}
          placeholder={t('consumer:YourContactPerson')}
          value={contactPerson}
          onChange={value => {
            onFieldsChange({ contactPerson: value });
          }}
          validationFunction={isNotEmpty}
          disabled={isGuest}
        />
        <DeferredInputWithLabel
          labelText={t('common:phoneNumber')}
          placeholder={t('consumer:ContactPhoneNumber')}
          value={phone}
          onChange={value => {
            onFieldsChange({ phone: value });
          }}
          validationFunction={isNotEmpty}
          disabled={isGuest}
        />
      </Container>
      <EditDeliveryInformationRedirector
        deliveryAddress={deliveryAddress}
        deliveryWindow={deliveryWindow}
        deliveryInfo={deliveryInfo}
      />
      <Container mt="jumbo">
        <Typography variant="secondaryHeading">
          {t('common:TradeInsightAndData')}
        </Typography>
        <ProfileDataExport />
      </Container>
      <Container mt="jumbo">
        <Typography variant="secondaryHeading">
          {t('common:UserAccess')}
        </Typography>
        <EditUsers />
      </Container>
      <Container mt="jumbo" mb="l">
        <Typography variant="secondaryHeading">
          {t('common:Notifications')}
        </Typography>
        <NotificationSettings />
      </Container>
      <EmailNotifications
        onFieldsChange={onFieldsChange}
        unsubscribeFavoriteNotification={unsubscribeFavoriteNotification}
        unsubscribeWeeklyReminderNotification={
          unsubscribeWeeklyReminderNotification
        }
        disabled={isGuest}
      />
      <Container my="jumbo">
        <Typography variant="secondaryHeading">
          {t('common:BillingAccountHeader')}
        </Typography>
        <Typography variant="paragraph" mt="xxs" mb="xs">
          {t('common:BillingAccountLinkDescription')}
        </Typography>
        <ButtonLink to="/billing-account" variant="ghost">
          <Icon icon="editSmall" mr={Theme.Spacings.xs} />
          {t('common:BillingAccountLinkButton')}
        </ButtonLink>
      </Container>
      <Container my="jumbo">
        <SelectWithLabel
          selectedValue={roleLang}
          options={Object.keys(AvailableLanguages).map(lng => {
            return {
              value: AvailableLanguages[lng as keyof typeof AvailableLanguages],
              name: t(`common:${lng}`)
            };
          })}
          onChange={async option => {
            await onFieldsChange({ roleLang: option });
            await i18n.changeLanguage(option);
            setSaved(true);
          }}
          labelText={t('common:Language')}
          savedText={t('common:Saved')}
          saved={saved}
          disabled={isGuest}
        />
      </Container>
      <Typography variant="paragraphSmall">
        {t('consumer:IfSomethingIsNotRightReachUsHere')} <DagensPhoneLink />
      </Typography>
    </>
  );
};

export default ConsumerProfileFields;
