import { Box } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import React from 'react';
import { CommonNavbar } from '../../../components/navbar';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import PageHeader from '@components/page-header/PageHeader';
import Button, { ButtonLink } from '_common/components/button/Button.style';
import DeliveryDatePicker, {
  SelectedDeliveryDate
} from '_common/components/delivery-date-picker/DeliveryDatePicker';
import { Page } from '@components/page';
import { Consumer } from 'types/Consumer';
import { getDagensDeliveryDates } from 'utils/delivery';
import { ReqType } from 'utils/REQ';

type AddOrderDeliveryDateProps = {
  selectedDeliveryDates: SelectedDeliveryDate[];
  onSelectedDeliveryDateChange: (date: SelectedDeliveryDate[]) => void;
  deliveryDates: string[];
  deliveryRoutes: any;
  producerId?: string;
  selectedConsumer: Consumer;
  // We need to fix a standardized HOC solution

  req: ReqType;
};

const AddOrderDeliveryDate = ({
  selectedDeliveryDates,
  onSelectedDeliveryDateChange,
  deliveryDates,
  deliveryRoutes,
  producerId,
  selectedConsumer
}: AddOrderDeliveryDateProps) => {
  const { t } = useTranslation();

  const dagensDeliveryDates = React.useMemo(() => {
    return getDagensDeliveryDates(deliveryRoutes, producerId);
  }, [deliveryRoutes, producerId]);

  const memoIsDagensDeliveryDate = React.useCallback(
    (slugifiedDate: string) => {
      return dagensDeliveryDates.includes(slugifiedDate);
    },
    [dagensDeliveryDates]
  );

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t('producer:SelectDeliveryDays')}
              subTitle={`${t('common:to')} ${selectedConsumer.name}`}
              annotation={`${t('common:MakeNewOrder')} (2/4)`}
            />
            <Typography my="m">
              {t('producer:selectDeliveryDaysDescription')}
            </Typography>
          </Box.FullWidth>
        </>
      }
      bottom={
        <Box.BottomSheet border>
          <ButtonLink to="/orders/add/start">
            {t('common:BackWithArrow')}
          </ButtonLink>
          {selectedDeliveryDates.length === 0 && (
            <Button disabled>{t('common:NextWithArrow')}</Button>
          )}
          {selectedDeliveryDates.length > 0 && (
            <ButtonLink
              to="/orders/add/products"
              variant="primary"
              state={{
                consumer: selectedConsumer,
                deliveryDates: selectedDeliveryDates
              }}
            >
              {t('common:NextWithArrow')}
            </ButtonLink>
          )}
        </Box.BottomSheet>
      }
    >
      <Container mb="xl">
        <DeliveryDatePicker
          mode="multiple"
          selectedDeliveryDates={selectedDeliveryDates}
          onSelectedDeliveryDateChange={onSelectedDeliveryDateChange}
          isDagensDeliveryDate={memoIsDagensDeliveryDate}
          deliveryDates={deliveryDates}
          deliveryHubInfo=""
        />
      </Container>
    </Page>
  );
};

export default AddOrderDeliveryDate;
