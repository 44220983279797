import { ComboboxInput } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useRef } from 'react';
import { tv } from '../../../utils/tv';
import { inputStyle } from '../../input/styles';
import { nbsp } from '../../../utils/nbsp';

const multiStyle = tv({
  base: `flex-1`,
  variants: {
    hasValue: {
      true: `
        [&:not(:placeholder-shown)]:min-w-[50%]

        placeholder-shown:max-w-[20px]
      `
    }
  }
});

type MultiProps<T> = {
  inputId: string;
  error?: boolean;
  placeholder?: string;
  setQuery: (value: string) => void;
  value?: T[];
  query: string;
  onBackspaceChip: () => void;
};

export const ComboboxInputMulti = <T,>({
  inputId,
  placeholder,
  error,
  value,
  query,
  setQuery,
  onBackspaceChip
}: MultiProps<T>) => {
  const ref = useRef<HTMLInputElement>(null);
  const { input } = inputStyle({ error });
  const hasValue = value !== undefined && value.length > 0;
  return (
    <ComboboxInput
      ref={ref}
      id={inputId}
      autoComplete="off"
      className={twMerge(input(), multiStyle({ hasValue }))}
      placeholder={hasValue ? nbsp : placeholder}
      value={query}
      onChange={event => setQuery(event.target.value)}
      onKeyDown={event => {
        if (event.key === 'Backspace' && ref.current?.selectionStart === 0) {
          onBackspaceChip();
        }
      }}
    />
  );
};
