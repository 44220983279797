import { ForwardedRef, forwardRef } from 'react';
import { tv } from '../../../utils/tv';
import { ButtonComponent, ButtonComponentProps } from '../components/as-button';
import { Icon } from '../../../theme/icon';

const style = tv({
  base: `
    justify-between
    rounded-none
    !text-paragraphSmall
    text-black

    disabled:bg-transparent
    disabled:text-secondary/50
    disabled:hover:pointer-events-none

    group-data-[focus]:!bg-brightPurple
  `,
  variants: {
    active: {
      true: `bg-brightPurple/50`
    }
  }
});

type Props = Omit<
  ButtonComponentProps,
  'styles' | 'className' | 'as' | 'type'
> & {
  active?: boolean;
};

const Component = (
  { active, children, ...props }: Props,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <ButtonComponent
      {...props}
      buttonRef={ref}
      variant="borderless"
      size="small"
      alignment="left"
      className={style({ active })}
    >
      {children}
      {active && <Icon icon="check" size="small" />}
    </ButtonComponent>
  );
};

export const DropdownItem = forwardRef(Component);
