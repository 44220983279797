import { tv } from '../../../utils/tv';

import { ButtonWrapperInternal } from '../components';
import { ButtonComponentProps } from '../components/as-button';
import { LinkComponentProps } from '../components/as-link';

const style = tv({
  base: `
    p-xs
    text-black
  `
});

type Props =
  | Omit<ButtonComponentProps, 'styles' | 'className' | 'type'>
  | Omit<LinkComponentProps, 'styles' | 'className'>;

export const NavIcon = (props: Props) => {
  return (
    <ButtonWrapperInternal
      {...props}
      variant="borderless"
      size="regular"
      className={style()}
    />
  );
};
