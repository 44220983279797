import { PropsWithChildren, Ref } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Simplify } from 'type-fest';

import { buttonStyle, ButtonStyles } from '../styles';

export type LinkComponentProps = Simplify<
  PropsWithChildren<
    ButtonStyles & {
      as: 'link';
      linkRef?: Ref<HTMLAnchorElement>;

      className?: LinkProps['className'];
      state?: LinkProps['state'];
      tabIndex?: LinkProps['tabIndex'];
      to: LinkProps['to'];
      onClick?: LinkProps['onClick'];
    }
  >
>;

export const LinkComponent = ({
  // style props
  alignment,
  fillParent,
  focused,
  size,
  variant,

  // link props
  state,
  to,

  // react props
  children,
  className,
  linkRef,
  tabIndex,
  onClick
}: LinkComponentProps) => {
  return (
    <Link
      ref={linkRef}
      className={twMerge(
        buttonStyle({
          alignment,
          fillParent,
          focused,
          size,
          variant
        }),
        className
      )}
      to={to}
      state={state}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {children}
    </Link>
  );
};
