import { Form } from '@dagens/frontend-forms';
import { createPortal } from 'react-dom';
import { ReqType } from '../../../utils/REQ';
import { PricingFormValues } from '../pricing-form';
import { ProductFormSubmitButton as Submit } from './parts/submit-button';
import { ProductFormFields as Fields } from './parts/fields';
import { ProductFormValues } from '.';

type Props = {
  saveReq: ReqType;
  defaultValue?: ProductFormValues & PricingFormValues;
  onSubmit: (data: ProductFormValues & PricingFormValues) => void;
  submitButtonContainer: HTMLDivElement | null;
};

// The create form has both the product fields
// and the pricing fields for it's default price
export const ProductCreateForm = ({
  saveReq,
  defaultValue,
  onSubmit,
  submitButtonContainer
}: Props) => {
  return (
    <Form<ProductFormValues & PricingFormValues>
      defaultValue={defaultValue}
      onSubmit={onSubmit}
    >
      <Fields flow="create" defaultValue={defaultValue} />
      {submitButtonContainer &&
        createPortal(
          <Submit flow="create" saveReq={saveReq} onSubmit={onSubmit} />,
          submitButtonContainer
        )}
    </Form>
  );
};
