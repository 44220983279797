export const useProducerPlans = (
  transactionCut: number,
  transactionCutForSelfDelivery: number
) => {
  return {
    platformFee: transactionCutForSelfDelivery,
    deliveryFee:
      Math.round((transactionCut - transactionCutForSelfDelivery) * 100) / 100
  };
};
