import { Button, Box } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import CheckboxWithLabel from '../../../_common/components/checkbox/CheckboxWithLabel';
import NewRadioButtons from '../../../_common/components/radio-buttons/NewRadioButtons';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { DeliveryType, Hub } from '../../../types/Logistics';
import { useThrowError } from '../../../utils/errorHandling';
import REQ, { ReqType } from '../../../utils/REQ';
import { joinArrayOptions } from '../../../utils/texts';
import { onSubmit } from './shared';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { Page } from '@components/page';
import PageHeader from '@components/page-header/PageHeader';

type LocationState =
  | {
      deliveryType: DeliveryType | undefined;
      distributionAreaId: string | null | undefined;
      hubsByCountry: Hub[];
    }
  | undefined;

const DeliveryRouteRequestPageHub = () => {
  const [submitReq, setSubmitReq] = useState<ReqType>(REQ.INIT);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as LocationState;
  const { deliveryType, distributionAreaId, hubsByCountry } = state ?? {};

  const throwAsyncError = useThrowError();
  const [hub, setHub] = useState<string | null | undefined>();
  const [deliveryDays, setDeliveryDays] = useState<string[]>([]);

  const handleDeliveryDayUpdated = (day: string) => {
    if (deliveryDays.indexOf(day) !== -1) {
      setDeliveryDays(
        deliveryDays.filter(d => {
          return d !== day;
        })
      );
    } else {
      setDeliveryDays([...deliveryDays, day]);
    }
  };

  const { roleId } = useAppSelector(({ auth }) => {
    return {
      roleId: auth._id
    };
  });

  const filteredHubs = hubsByCountry?.filter(h => {
    return h.routeOptions.some(routeOption => {
      return routeOption.distributionAreaId === distributionAreaId;
    });
  });

  const selectedHubData = filteredHubs?.find(h => {
    return h.id === hub;
  });

  const selectedHubRouteOption = selectedHubData?.routeOptions.find(
    routeOption => {
      return routeOption.distributionAreaId === distributionAreaId;
    }
  );

  useEffect(() => {
    if (!hub) {
      setHub(filteredHubs?.at(0)?.id);
    }
  }, []);

  useEffect(() => {
    if (hub && selectedHubRouteOption) {
      setDeliveryDays(selectedHubRouteOption.pickupDays);
    }
  }, [hub]);

  if (!deliveryType || !distributionAreaId) {
    return <Navigate to="/profile/delivery-route-request/start" />;
  }

  if (!selectedHubRouteOption) {
    return <Page.Error />;
  }

  return (
    <Page
      bottom={
        <Box.BottomSheet>
          <Button
            variant="primary"
            size="fullWidth"
            disabled={deliveryDays.length === 0 || submitReq === REQ.PENDING}
            onClick={async () => {
              setSubmitReq(REQ.PENDING);
              const { req, data } = await onSubmit({
                deliveryDays,
                deliveryType,
                distributionAreaId,
                hubsByCountry: hubsByCountry ?? [],
                hub,
                roleId
              });
              if (req === REQ.SUCCESS) {
                navigate('/profile/delivery-route-request/complete', {
                  state: { details: data.details }
                });
              } else {
                throwAsyncError(
                  new Error(
                    `Could not submit delivery route request for user id: ${roleId}`
                  )
                );
              }
            }}
          >
            {t('producer:DeliveryRouteRequest.NextButtonHub')}
          </Button>
        </Box.BottomSheet>
      }
    >
      <PageHeader
        headerText={t('producer:DeliveryRouteRequest.PageHeader')}
        subTitle={t('common:DagensDelivery')}
      />
      <Container mb="xl">
        <NewRadioButtons
          labelText={t('producer:DeliveryRouteRequest.RadioButtonsHubHeader')}
          radioGroupName="selectDeliveryType"
          currentValue={hub}
          onChange={key => {
            return setHub(key);
          }}
          options={
            filteredHubs?.map(h => {
              return {
                key: h.id,
                value: `${h.name} (${h.hubArea})`,
                explanationText: joinArrayOptions(
                  h.temperatureZones.map(temperatureZone => {
                    return t(`common:${temperatureZone}`);
                  }),
                  t('common:and')
                )
              };
            }) ?? []
          }
        />
      </Container>
      <Typography variant="inputLabel" as="p" mb="xxs">
        {t('producer:DeliveryRouteRequest.CheckboxesDaysHeader')}
      </Typography>
      <Container gap="xs" mb="m">
        {selectedHubRouteOption.pickupDays.map(day => {
          return (
            <CheckboxWithLabel
              name={day}
              key={day}
              label={`${capitalize(
                t(`calendar:days.${day}`, { count: 2 })
              )}, ${t(`common:before`)} ${selectedHubData?.deadline}`}
              checked={deliveryDays.indexOf(day) !== -1}
              onChange={() => {
                handleDeliveryDayUpdated(day);
              }}
            />
          );
        })}
      </Container>
    </Page>
  );
};

export default DeliveryRouteRequestPageHub;
