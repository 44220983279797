import { tv } from '../../utils/tv';

export const buttonStyle = tv({
  base: `
    group
    transition-regular
    inline-flex
    items-center
    gap-xs
    whitespace-nowrap
    rounded
    !text-button
    transition-[color,background-color,border-color,opacity]

    focus-visible:!focus-style

    focus:outline-none

    print:hidden
  `,
  variants: {
    variant: {
      primary: `
        bg-dagensPurple
        text-black

        active:bg-deepPurple
        active:text-white

        disabled:bg-lightGrey
        disabled:text-secondary

        focus-visible:bg-brightPurple

        media-hover:hover:bg-brightPurple
      `,
      secondary: `
        bg-black
        text-white

        active:bg-brightPurple
        active:text-black

        disabled:bg-lightGrey
        disabled:text-secondary

        focus-visible:bg-deepPurple

        media-hover:hover:bg-deepPurple
      `,
      ghost: `
        bg-transparent
        text-black
        ring-2
        ring-inset
        ring-black

        active:bg-black
        active:text-white

        disabled:bg-lightGrey
        disabled:text-secondary
        disabled:ring-0

        focus-visible:bg-brightPurple

        media-hover:hover:bg-brightPurple
      `,
      borderless: `
        bg-transparent
        text-deepPurple

        active:bg-deepPurple
        active:text-white

        disabled:bg-lightGrey
        disabled:text-secondary

        focus-visible:bg-brightPurple

        media-hover:hover:bg-brightPurple
      `
    },
    size: {
      small: 'p-xs',
      regular: 'p-s',
      fullWidth: `
        w-full
        p-s

        tablet:w-auto
        tablet:min-w-[25%]
        tablet:max-w-max-content
      `
    },
    alignment: {
      left: 'justify-start',
      center: 'justify-center'
    },
    focused: {
      true: ''
    },
    fillParent: {
      true: `
        before:absolute
        before:inset-0
      `
    }
  },
  compoundVariants: [
    // Focused/variants styles
    {
      variant: 'primary',
      focused: true,
      className: 'bg-brightPurple'
    },
    {
      variant: 'secondary',
      focused: true,
      className: 'bg-deepPurple'
    },
    {
      variant: 'ghost',
      focused: true,
      className: 'bg-brightPurple'
    },
    {
      variant: 'borderless',
      focused: true,
      className: 'bg-brightPurple'
    }
  ],
  defaultVariants: {
    variant: 'primary',
    size: 'regular',
    alignment: 'center',
    focused: false
  }
});

type ButtonVariant = keyof typeof buttonStyle.variants.variant;
type ButtonSize = keyof typeof buttonStyle.variants.size;
type ButtonAlignment = keyof typeof buttonStyle.variants.alignment;

export type ButtonStyles = {
  alignment?: ButtonAlignment;
  fillParent?: boolean;
  focused?: boolean;
  size?: ButtonSize;
  variant?: ButtonVariant;
};
