import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState
} from 'react';
import { twMerge } from 'tailwind-merge';
import { evaluate, round } from 'mathjs';
import { tv } from '../../utils/tv';
import { inputStyle } from '../input/styles';

const stringToValue = (value: string, type: 'integer' | 'float') => {
  if (value === '') {
    return null;
  }

  try {
    const result = evaluate(value);
    const n = typeof result === 'number' ? result : result.value;
    return type === 'integer' ? round(n, 0) : round(n, 2);
  } catch (e) {
    return value;
  }
};

const style = tv({
  slots: {
    container: ``,
    input: `text-right`,
    unit: `
      text-inputText
      text-secondary
    `
  },
  variants: {
    small: {
      true: {
        container: `w-[181px]`
      }
    }
  }
});

type InputQuantityType = 'integer' | 'float';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export type NumberInputProps = {
  type?: InputQuantityType;
  unit?: string;
  placeholder?: InputProps['placeholder'];
  error?: boolean;
  value: number | string | null;
  small?: boolean;
  onChange: (value: number | string | null) => void;
};

const Component = (
  {
    type = 'integer',
    unit,
    error,
    placeholder = '#',
    value,
    small,
    onChange,
    ...props
  }: NumberInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [inputValue, setInputValue] = useState(value?.toString() ?? '');
  const {
    container: numberContainer,
    input: numberInput,
    unit: unitStyle
  } = style({ small });
  const { container, input } = inputStyle({ error });

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      return;
    }
    setInputValue(stringToValue(value, type)?.toString() ?? value);
  };

  useEffect(() => {
    onChange?.(stringToValue(inputValue, type));
  }, [inputValue, type]);

  return (
    <label className={twMerge(container(), numberContainer())}>
      <input
        {...props}
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
        onBlur={onBlur}
        placeholder={placeholder}
        type="text"
        ref={ref}
        className={twMerge(input(), numberInput())}
      />
      {unit && <span className={unitStyle()}>{unit}</span>}
    </label>
  );
};

export const NumberInput = forwardRef(Component);
