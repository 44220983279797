import React, { ReactNode } from 'react';

import { useMediaQuery } from '@dagens/carrot';
import { Annotation } from './Annotation';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';

type PageHeaderProps = {
  headerText?: ReactNode;
  subTitle?: ReactNode;
  annotation?: string;
};

const SubTitle = ({ subTitle }: { subTitle: PageHeaderProps['subTitle'] }) => {
  return React.isValidElement(subTitle) ? (
    subTitle
  ) : (
    <Typography variant="paragraphSmall" color="secondary">
      {subTitle}
    </Typography>
  );
};

const PageHeader = ({
  headerText = '',
  subTitle = '',
  annotation
}: PageHeaderProps) => {
  const { tabletUp } = useMediaQuery();
  return (
    <Container mt={tabletUp ? 'm' : undefined} mb="m">
      <Annotation text={annotation} />
      <Typography variant="primaryHeading" mb="xs">
        {headerText}
      </Typography>
      <SubTitle subTitle={subTitle} />
    </Container>
  );
};

export default PageHeader;
