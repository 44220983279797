import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import { Box } from '@dagens/carrot';
import { ProductForm } from '../../components/product-form';
import { RootState } from '../../../store';
import Loader from '../../../_common/components/loader/Loader';
import { useOnSave } from './use-on-save';
import { useDefaultValue } from './use-default-value';
import PageHeader from '@components/page-header/PageHeader';
import { useAppSelector } from '_common/hooks/reduxHooks';
import usePageView from '_common/hooks/usePageView';
import { Page } from '@components/page';
import { ADD_PRODUCT_PAGE_VIEW } from 'utils/mixpanel';

export const AddProductPage = () => {
  usePageView(ADD_PRODUCT_PAGE_VIEW);

  const [bottomRef, setBottomRef] = useState<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { onSave, saveReq } = useOnSave();
  const { defaultValue, req } = useDefaultValue();
  const producerName = useAppSelector(({ auth: { name } }: RootState) => name);

  return (
    <Page bottom={<Box.BottomSheet ref={setBottomRef} />}>
      <PageHeader
        headerText={t('producer:productAddPageHeader')}
        subTitle={t('producer:productAddNewFromProducer', {
          producerName
        })}
      />
      <Box.FormWrapper>
        {req !== 'SUCCESS' ? (
          <Loader />
        ) : (
          <ProductForm.Create
            saveReq={saveReq}
            defaultValue={defaultValue}
            onSubmit={onSave}
            submitButtonContainer={bottomRef}
          />
        )}
      </Box.FormWrapper>
    </Page>
  );
};
