import { useTranslation } from '@dagens/frontend-i18n';
import { find } from 'lodash';
import { useState } from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import { ContentLoader } from '../../../components/content-loader';
import PageHeader from '@components/page-header/PageHeader';
import ProductCard from '_common/components/product-card/ProductCard';
import {
  DELIVERY_DATE_CONTEXT_ISOLATED,
  DeliveryDateContext
} from '_common/context/DeliveryDateContext';
import usePageView from '_common/hooks/usePageView';
import Grid from '_consumer/pages/landing-page/parts/Grid';
import { showProductModal } from '_common/reducers/modal';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';
import { PAGE_SIZE } from 'config';
import {
  CONSUMER_VIEW_FAVORITE_PAGE,
  FAVORITES_SEASON_CAL_CLICKED,
  track
} from 'utils/mixpanel';

const FavoritesPage = () => {
  const { favorites, producers, productsForSale, req } = useAppSelector(
    ({
      auth: { favorites = [] },
      productsForSale: { req, items: productsItems },
      producers: { items: producers }
    }) => {
      const consumersFavorites = productsItems.filter(product => {
        return favorites.some(({ _ref }) => {
          return _ref === product._id;
        });
      });

      return {
        req,
        favorites: consumersFavorites,
        producers,
        productsForSale: productsItems
      };
    }
  );
  const dispatch = useAppDispatch();

  usePageView(CONSUMER_VIEW_FAVORITE_PAGE);

  const [pageCount, setPageCount] = useState(PAGE_SIZE);

  const { t } = useTranslation();

  const onProductClick = (productId: string) => {
    track(FAVORITES_SEASON_CAL_CLICKED);
    const producerId = find(productsForSale, { _id: productId })?.producer._id;
    const producerProducts = productsForSale.filter(({ producer: { _id } }) => {
      return _id === producerId;
    });
    const producer = find(producers, { _id: producerId });
    dispatch(
      showProductModal({
        producerProducts,
        producer,
        productId,
        deliveryDateContext: DELIVERY_DATE_CONTEXT_ISOLATED
      }) as any
    );
  };

  return (
    <DeliveryDateContext.Provider
      value={{ pageContext: DELIVERY_DATE_CONTEXT_ISOLATED }}
    >
      <Page header={<CommonNavbar showLogo />}>
        <PageHeader
          headerText={`${t('consumer:MyFavorites')} (${favorites.length || 0})`}
        />
        <ContentLoader req={req}>
          <Grid
            hasMore={pageCount < favorites.length}
            loadMore={() => {
              return setPageCount(prevCount => {
                return prevCount + PAGE_SIZE;
              });
            }}
          >
            <>
              {favorites.slice(0, pageCount).map(product => {
                return (
                  <ProductCard
                    product={product}
                    key={product._id}
                    onClick={() => {
                      onProductClick(product._id);
                    }}
                  />
                );
              })}
            </>
          </Grid>
        </ContentLoader>
      </Page>
    </DeliveryDateContext.Provider>
  );
};

export default FavoritesPage;
