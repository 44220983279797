import { useTranslation } from '@dagens/frontend-i18n';
import { capitalize, find } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@dagens/carrot';
import { ProducerProduct } from '../../../types/Product';
import Loader from '../../../_common/components/loader/Loader';
import { ProductForm } from '../../components/product-form';
import { formatDateWithTime } from '../../../utils/date/format';
import Container from '../../../carrot-deprecated/container';
import { useOnSave } from './use-on-save';
import { useDefaultValue } from './use-default-value';
import { useOnDelete } from './use-on-delete';
import PageHeader from '@components/page-header/PageHeader';
import { useAppSelector } from '_common/hooks/reduxHooks';
import usePageView from '_common/hooks/usePageView';
import { Page } from '@components/page';
import { EDIT_PRODUCT_PAGE_VIEW } from 'utils/mixpanel';
import REQ from 'utils/REQ';

type Props = {
  product: ProducerProduct;
};

const EditProductPage = ({ product }: Props) => {
  usePageView(EDIT_PRODUCT_PAGE_VIEW);

  const [bottomRef, setBottomRef] = useState<HTMLDivElement | null>(null);
  const [titleRef, setTitleRef] = useState<HTMLSpanElement | null>(null);
  const { t } = useTranslation();
  const onDelete = useOnDelete(product);
  const { onSave, saveReq } = useOnSave(product);
  const { defaultValue, req: defaultValueReq } = useDefaultValue(product);

  if (!product) {
    return null;
  }

  return (
    <Page bottom={<Box.BottomSheet ref={setBottomRef} />}>
      <PageHeader
        headerText={<span ref={setTitleRef}></span>}
        subTitle={`${t('producer:productLastEdited')}  ${
          product._updatedAt
            ? capitalize(formatDateWithTime(product._updatedAt))
            : ''
        }`}
      />
      <Container mb="jumbo">
        <Box.FormWrapper>
          {defaultValueReq !== 'SUCCESS' ? (
            <Loader />
          ) : (
            <ProductForm.Edit
              product={product}
              saveReq={saveReq}
              defaultValue={defaultValue}
              onDelete={onDelete}
              onSubmit={onSave}
              submitButtonContainer={bottomRef}
              titleContainer={titleRef}
            />
          )}
        </Box.FormWrapper>
      </Container>
    </Page>
  );
};

const EditProductPageFetcher = () => {
  const { id } = useParams();
  const { product, req } = useAppSelector(
    ({ producerProducts: { items, req } }) => {
      const product = find(items, { _id: id });
      return {
        req: req === REQ.SUCCESS && !product ? REQ.NOT_FOUND : req,
        product
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  if (!product) {
    return <Page.NotFound />;
  }

  return <EditProductPage key={id} product={product} />;
};

export default EditProductPageFetcher;
