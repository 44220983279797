/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import {
  AvailableLanguages,
  type ConsumerOnboardingStepValue,
  type Organization
} from '@dagensmat/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { find, get } from 'lodash';
import { Order } from '../../types/Order';
import { getIdentity, getOnboardingHistory } from 'api';
import { defaultDistributionAreaId } from 'config';
import { type AppDispatch } from 'store';
import { Address, BillingAccount } from 'types/BillingAccount';
import { Consumer, ConsumerFields } from 'types/Consumer';
import {
  type Producer,
  type ProducerFields,
  type ProducerOnboarding
} from 'types/Producer';
import { type Favorite } from 'types/Product';
import {
  getCurrentRoleIdFromLocalStorage,
  updateLocalStorage
} from 'utils/clientcache';
import {
  AUTHENTICATED,
  setupRole as setupMixpanelRole,
  SWITCH_ROLE,
  track
} from 'utils/mixpanel';
import { type Onboarding as ConsumerOnboarding } from 'utils/role';

export type RoleType = 'user' | 'consumers' | 'producers';

export type User = {
  _id: string;
  _type: RoleType;
  _updatedAt?: string;
  isVerified?: boolean;
  loginEmail?: string;
  uid?: string;
  options?: User[];
} & Role &
  Partial<ConsumerFields> &
  Partial<ProducerFields>;

type Role = {
  emails?: string[];
  loginEmails?: string[];
  name: string;
  phone?: string;
  organization?: Organization;
  billingAccount?: Partial<BillingAccount>;
  roleLang: AvailableLanguages;
  contactPerson: string;
  onboarding?: ConsumerOnboarding;
  onboardingHistory?: ProducerOnboarding;
};

export type LabelFormat = '1' | '2';

export type AuthState = {
  authenticated?: boolean;
  isMeyersAccount?: boolean;
  labelFormat?: LabelFormat;
} & Partial<User>;

const guestConsumer = {
  _id: 'guest-consumer',
  _type: 'consumers' as const,
  name: 'Gjest',
  contactPerson: 'Gjest',
  isApprovedToOrder: false,
  distributionAreaId: defaultDistributionAreaId,
  roleLang: i18n.resolvedLanguage as AvailableLanguages,
  emails: []
};

const switchRole = (options: User['options'], roleId: User['uid']) => {
  const role = find(options, { _id: roleId });

  updateLocalStorage('currentRoleId', roleId);
  if (role?.roleLang) i18n.changeLanguage(role.roleLang);
  setupMixpanelRole(role);
  track(SWITCH_ROLE);

  return {
    ...role,
    options
  };
};

const getCurrentRole = (roles: User[] = []) => {
  const roleId = getCurrentRoleIdFromLocalStorage();
  const current = roles.find(({ _id }) => {
    return _id === roleId;
  });

  if (current) {
    return current;
  }

  const [first] = roles;

  return first;
};

const initialState = {
  authenticated: undefined,
  favorites: []
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userAuthenticated(_state, action: PayloadAction<Partial<User>>) {
      return { ...action.payload, authenticated: true };
    },
    guest() {
      return {
        authenticated: false,
        distributionAreaId: defaultDistributionAreaId,
        _id: 'unauth-user',
        favorites: [],
        contactPerson: ''
      };
    },
    changeUserRole(state, action: PayloadAction<User['uid']>) {
      return {
        ...switchRole(state.options, action.payload),
        authenticated: true
      };
    },
    userUpdated(state, action: PayloadAction<AuthState>) {
      return { ...state, ...action.payload };
    },
    billingAccountUpdated(
      state,
      action: PayloadAction<Partial<BillingAccount>>
    ) {
      const newBillingAccountState = {
        ...state.billingAccount,
        ...action.payload
      };
      state.billingAccount = newBillingAccountState;
    },
    billingAddressUpdated(state, action: PayloadAction<Partial<Address>>) {
      const newBillingAccountAddressState = {
        ...state.billingAccount?.address,
        ...action.payload
      };
      if (!state.billingAccount) {
        state.billingAccount = { address: newBillingAccountAddressState };
      } else {
        state.billingAccount.address = newBillingAccountAddressState;
      }
    },
    likedProduct(state, action: PayloadAction<Favorite['_key']>) {
      const productId = action.payload;
      const favorite = {
        _key: productId,
        _ref: productId,
        _type: 'products' as const
      };
      if (state.favorites) {
        state.favorites.push(favorite);
      } else {
        state.favorites = [favorite];
      }
    },
    unlikedProduct(state, action: PayloadAction<Favorite['_key']>) {
      state.favorites = state.favorites?.filter(({ _ref }) => {
        return _ref !== action.payload;
      });
    },
    consumerOnboardingUpdated(
      state,
      action: PayloadAction<ConsumerOnboardingStepValue>
    ) {
      state.onboarding = { [action.payload]: new Date().toISOString() };
    },
    onboardingHistoryUpdated(state, action: PayloadAction<ProducerOnboarding>) {
      state.onboardingHistory = action.payload;
    },
    minimumOrderAmountUpdated(
      state,
      action: PayloadAction<Partial<ProducerFields>>
    ) {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const {
  userAuthenticated,
  guest,
  changeUserRole,
  userUpdated,
  billingAccountUpdated,
  billingAddressUpdated,
  likedProduct,
  unlikedProduct,
  consumerOnboardingUpdated,
  onboardingHistoryUpdated,
  minimumOrderAmountUpdated
} = authSlice.actions;

export default authSlice.reducer;

export type ChangeRoleDTO = {
  uid: User['uid'];
  email: User['loginEmail'];
  roles: User['options'];
};

const guessDistributionAreaId = (currentRole: User) => {
  return currentRole._type === 'producers'
    ? get(
        currentRole,
        'logisticsRoutes[0].distributionArea._ref',
        defaultDistributionAreaId
      )
    : undefined;
};

const setupRoles = (dispatch: AppDispatch, data: ChangeRoleDTO) => {
  const { uid, email: loginEmail, roles } = data;
  const rolesWithUid = roles?.map(role => {
    return { uid, loginEmail, ...role };
  });
  const current = getCurrentRole(rolesWithUid);

  const currentGuestConsumer = { ...guestConsumer, uid, loginEmail };

  if (current) {
    updateLocalStorage('currentRoleId', current._id);
    i18n.changeLanguage(current.roleLang);
    updateLocalStorage('fromHeader', loginEmail);
    setupMixpanelRole(current);

    const distributionAreaId = guessDistributionAreaId(current);
    const userData = !rolesWithUid?.find(({ _type }) => {
      return _type === 'consumers';
    })
      ? {
          ...current,
          options: [
            ...(rolesWithUid ?? []),
            { ...currentGuestConsumer, distributionAreaId }
          ]
        }
      : { ...current, options: rolesWithUid };

    dispatch(userAuthenticated(userData));
  } else {
    dispatch(
      userAuthenticated({
        uid,
        loginEmail,
        options: []
      })
    );
  }
};

const setupLoggedInUser = (dispatch: AppDispatch, data: ChangeRoleDTO) => {
  if (data && data.uid) {
    setupRoles(dispatch, data);
    track(AUTHENTICATED);
  } else {
    dispatch(guest());
  }
};

export const loggedIn = (data: ChangeRoleDTO) => {
  return (dispatch: AppDispatch) => {
    setupLoggedInUser(dispatch, data);
  };
};

export const loggedOut = () => {
  return (dispatch: AppDispatch) => {
    dispatch(guest());
  };
};

export const checkAuth = () => {
  return (dispatch: AppDispatch) => {
    getIdentity({}).then(data => {
      setupLoggedInUser(dispatch, data);
    });
  };
};

export const updateOnboardingHistory = (id: string | undefined) => {
  return (dispatch: AppDispatch) => {
    getOnboardingHistory(id).then(data => {
      dispatch(onboardingHistoryUpdated(data));
    });
  };
};

/** Selectors */

export const getUserFirstName = (
  user: Partial<User> | Consumer | Producer | Order['consumer'] | undefined
) => {
  return user?.contactPerson?.split(' ')[0] ?? '';
};
