import { Form as CarrotForm } from '@dagens/carrot';
import { PropsWithChildren } from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';

type Props<Form extends FieldValues> = PropsWithChildren<{
  defaultValue?: DefaultValues<Form>;
  onSubmit: SubmitHandler<Form>;
}>;

// Initializes the form with the default values, and handles the form submission
export const Form = <Form extends FieldValues>({
  defaultValue,
  onSubmit,
  children
}: Props<Form>) => {
  const methods = useForm<Form>({ defaultValues: defaultValue });
  return (
    <FormProvider {...methods}>
      <CarrotForm.Container onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </CarrotForm.Container>
    </FormProvider>
  );
};
