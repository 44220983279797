import { useMemo, useState } from 'react';
import { useTranslation } from '@dagens/frontend-i18n';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button } from '@dagens/carrot';
import {
  GetOrganizationFoundResponse,
  postProducerCreateCustomer
} from '../../../../api';
import { isValidEan } from '../../../../utils/billingAccount';
import { DeliveryInformationForm } from '../../../../components/delivery-information-form';
import { DeliveryWindow } from '../../../../types/Consumer';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import REQ, { ReqType } from '../../../../utils/REQ';
import Typography from '@carrot-deprecated/typography';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';
import PageHeader from '@components/page-header/PageHeader';

type LocationState =
  | {
      organization: GetOrganizationFoundResponse;
      billingAccountName: string;
      billingDepartmentName: string;
      billingEanNumber: string;
      billingPrimaryInvoiceEmail: string;
      billingReminderInvoiceEmail: string;
      contactInformationCustomerName: string;
      contactInformationContactPersonFullName: string;
      contactInformationContactPhone: string;
      contactInformationNotificationEmail: string;
    }
  | undefined;

const ReviewDeliveryInformation = () => {
  const [deliveryAddress, setDeliveryAddress] = useState<string>('');
  const [deliveryWindow, setDeliveryWindow] = useState<DeliveryWindow>({
    start: '08:00',
    end: '16:00'
  });
  const [deliveryInstructions, setDeliveryInstructions] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [submitReq, setSumbitReq] = useState<ReqType>(REQ.INIT);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const {
    organization,
    billingAccountName,
    billingDepartmentName,
    billingEanNumber,
    billingPrimaryInvoiceEmail,
    billingReminderInvoiceEmail,
    contactInformationCustomerName,
    contactInformationContactPersonFullName,
    contactInformationContactPhone,
    contactInformationNotificationEmail
  } = state || {};

  const { producerId, userId } = useAppSelector(({ auth }) => {
    return { producerId: auth._id, userId: auth.uid };
  });

  if (
    !producerId ||
    !userId ||
    !organization ||
    !billingAccountName ||
    (organization.country === 'dk' && !isValidEan(billingEanNumber)) ||
    !billingPrimaryInvoiceEmail ||
    !billingReminderInvoiceEmail ||
    !contactInformationCustomerName ||
    !contactInformationContactPersonFullName ||
    !contactInformationContactPhone ||
    !contactInformationNotificationEmail
  ) {
    return <Navigate to="/customers/add/start" />;
  }

  const canContinue = useMemo(() => {
    return deliveryAddress && deliveryWindow && !hasError;
  }, [deliveryAddress, deliveryWindow, hasError]);

  const handleSubmit = async () => {
    setSumbitReq(REQ.PENDING);
    console.log({
      organization,
      billingAccountName,
      billingDepartmentName,
      billingEanNumber,
      billingPrimaryInvoiceEmail,
      billingReminderInvoiceEmail,
      contactInformationCustomerName,
      contactInformationContactPersonFullName,
      contactInformationContactPhone,
      contactInformationNotificationEmail,
      deliveryAddress,
      deliveryWindow,
      deliveryInstructions
    });

    await postProducerCreateCustomer(producerId, {
      createdBy: userId,
      organizationName: organization.name,
      organizationNumber: organization.orgNumber,
      organizationAddress: organization.address,
      isVatRegistered: !organization.isNotVatRegistered,
      billingAccountName,
      billingDepartmentName: billingDepartmentName || undefined, // TODO: Fix this in page form instead of here
      billingEanNumber: billingEanNumber || undefined, // TODO: Fix this in page form instead of here
      billingPrimaryInvoiceEmail,
      billingReminderInvoiceEmail,
      contactInformationCustomerName,
      contactInformationContactPersonFullName,
      contactInformationContactPhone,
      contactInformationNotificationEmail,
      deliveryAddress,
      deliveryWindow,
      deliveryInstructions: deliveryInstructions || undefined // TODO: Fix this in page form instead of here
    });

    setSumbitReq(REQ.SUCCESS);

    // TODO: Handle success/error properly
    setTimeout(() => {
      navigate('/customers');
    }, 2000);
  };

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t(
                'producer:AddCustomer.reviewDeliveryInformationPageHeader'
              )}
              annotation={`${t('producer:AddCustomer.headerAnnotation')} (4/4)`}
            />
            <Typography>
              {t(
                'producer:AddCustomer.reviewDeliveryInformationPageDescription'
              )}
            </Typography>
          </Box.FullWidth>
        </>
      }
      bottom={
        <Box.BottomSheet border>
          <Button
            size="regular"
            variant="borderless"
            onClick={() => {
              navigate('/customers');
            }}
          >
            <Typography variant="button" color="error">
              {t('common:cancel')}
            </Typography>
          </Button>
          {submitReq === REQ.PENDING && (
            <Button variant="primary" disabled>
              Creating customer...
            </Button>
          )}
          {submitReq === REQ.SUCCESS && (
            <Typography variant="button">Customer created</Typography>
          )}
          {canContinue &&
          submitReq !== REQ.PENDING &&
          submitReq !== REQ.SUCCESS ? (
            <Button size="fullWidth" variant="primary" onClick={handleSubmit}>
              {t(
                'producer:AddCustomer.reviewDeliveryInformationButtonCreateCustomerText'
              )}
            </Button>
          ) : (
            <Button size="small" variant="primary" disabled>
              {t(
                'producer:AddCustomer.reviewDeliveryInformationButtonCreateCustomerText'
              )}
            </Button>
          )}
        </Box.BottomSheet>
      }
    >
      <DeliveryInformationForm
        deliveryAddress={deliveryAddress}
        onDeliveryAddressChange={(address: string) => {
          setDeliveryAddress(address);
        }}
        deliveryWindow={deliveryWindow}
        earliestDeliveryWindowHour={7}
        latestDeliveryWindowHour={17}
        minDeliveryWindowDuration={2}
        onDeliveryWindowChange={(deliveryWindow: DeliveryWindow) => {
          setDeliveryWindow(deliveryWindow);
        }}
        deliveryInstructions={deliveryInstructions}
        onDeliveryInstructionsChange={(instructions: string) => {
          setDeliveryInstructions(instructions);
        }}
        onErrorChange={(hasError: boolean) => {
          setHasError(hasError);
        }}
      />
    </Page>
  );
};

export default ReviewDeliveryInformation;
