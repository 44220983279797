import { useTranslation } from '@dagens/frontend-i18n';
import { find } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@dagens/carrot';
import { ProducerProduct } from '../../../types/Product';
import { PricingForm } from '../../components/pricing-form';
import { useOnSave } from './use-on-save';
import { useDefaultValue } from './use-default-value';
import PageHeader from '@components/page-header/PageHeader';
import { useAppSelector } from '_common/hooks/reduxHooks';
import usePageView from '_common/hooks/usePageView';
import { Page } from '@components/page';
import { EDIT_OFFER_PAGE_VIEW } from 'utils/mixpanel';
import REQ from 'utils/REQ';
import { formatProductNameAndType } from 'utils/texts';

type Props = {
  pricingKey: string | undefined;
  product?: ProducerProduct;
};

const EditPricingPage = ({ pricingKey, product }: Props) => {
  usePageView(EDIT_OFFER_PAGE_VIEW);

  const [bottomRef, setBottomRef] = useState<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { onSave, saveReq } = useOnSave(product, pricingKey);
  const { defaultValue, req: defaultValueReq } = useDefaultValue(
    pricingKey,
    product
  );

  if (!product) {
    return null;
  }

  return (
    <Page bottom={<Box.BottomSheet ref={setBottomRef} />}>
      <PageHeader
        headerText={t('producer:EditPricingHeader')}
        subTitle={formatProductNameAndType(product)}
      />
      <Box.FormWrapper>
        {defaultValueReq !== REQ.SUCCESS ? (
          <Page.Status req={defaultValueReq} />
        ) : (
          <PricingForm
            saveReq={saveReq}
            defaultValue={defaultValue}
            productCategoryId={product.categoryId}
            otherPrices={product.prices}
            onSubmit={onSave}
            submitButtonContainer={bottomRef}
          />
        )}
      </Box.FormWrapper>
    </Page>
  );
};

const EditPricingPageFetcher = () => {
  const { id, key } = useParams();
  const { pricingKey, product, req } = useAppSelector(
    ({ producerProducts: { items, req } }) => {
      const product = find(items, { _id: id });
      return {
        pricingKey: key,
        req: req === REQ.SUCCESS && !product ? REQ.NOT_FOUND : req,
        product
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return <EditPricingPage pricingKey={pricingKey} product={product} />;
};

export default EditPricingPageFetcher;
