import { Box, useMediaQuery } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useMemo, useState } from 'react';
import { tv } from 'tailwind-variants';
import {
  FeatureFlags,
  hasUserFeatureFlag
} from '../../../services/FeatureFlagService';
import { AddCustomerButtonLink } from './add-customer-button-link';
import Container from '@carrot-deprecated/container';
import PageHeader from '@components/page-header/PageHeader';
import SearchInput from '@components/search-input/SearchInput';
import { useAppSelector } from '_common/hooks/reduxHooks';
import usePageView from '_common/hooks/usePageView';
import { useQueryParams } from '_common/hooks/useQueryParams';
import { CustomerList } from '_producer/pages/customer-list-page/customer-list';
import { CustomerTable } from '_producer/pages/customer-list-page/customer-table';
import { CustomerListEmpty } from '_producer/pages/customer-list-page/empty';
import { ContentLoader } from '@components/content-loader';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';
import { Consumer } from 'types/Consumer';
import { CUSTOMER_LIST_PAGE_VIEW } from 'utils/mixpanel';
import REQ from 'utils/REQ';

const QUERY_KEY = 'query';

const style = tv({
  slots: {
    customerCountContainer: 'mb-s',
    customerCount: 'text-paragraphSmallBold',
    customerCountText: 'text-paragraphSmall'
  }
});

export const CustomerListPage = () => {
  usePageView(CUSTOMER_LIST_PAGE_VIEW);

  const { customerCount, customerCountContainer, customerCountText } = style();
  const { desktopUp } = useMediaQuery();
  const { getQueryValue, setQueryValue } = useQueryParams();
  const searchQuery = getQueryValue(QUERY_KEY);

  const { consumers, req, featureFlags } = useAppSelector(
    ({ auth, consumers: { items, req } }) => {
      return { consumers: items, req, featureFlags: auth.features };
    }
  );
  const [activeConsumer, setActiveConsumer] = useState<Consumer | null>(null);

  const filteredConsumers = useMemo(() => {
    return consumers
      .filter(consumer => {
        const searchFields = [
          consumer.name,
          ...consumer.emails,
          consumer.phone,
          consumer.contactPerson,
          consumer.deliveryAddress,
          consumer.structuredDeliveryAddress.addressLine1,
          consumer.structuredDeliveryAddress.addressLine2,
          consumer.structuredDeliveryAddress.city,
          consumer.structuredDeliveryAddress.country,
          consumer.structuredDeliveryAddress.postalCode
        ].join(' ');

        const hiddenCustomerTypes = ['B2C'];

        return (
          (!searchQuery ||
            searchFields.toLowerCase().includes(searchQuery.toLowerCase())) &&
          !hiddenCustomerTypes.includes(consumer.type)
        );
      })
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  }, [searchQuery, consumers]);

  const { t } = useTranslation();

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }
  if (consumers.length < 1) {
    return (
      <Page
        header={
          <CommonNavbar showLogo contextName={t('producers:Customers')} />
        }
      >
        <CustomerListEmpty
          title={t('producer:NoCustomersAvailableTitle')}
          description={t('producer:NoCustomersAvailableDescription')}
        />
      </Page>
    );
  }

  return (
    <Page
      header={
        <>
          <CommonNavbar showLogo />
          <Box.FullWidth>
            <Container
              flex
              direction="row"
              justify="space-between"
              alignItems="baseline"
            >
              <PageHeader headerText={t('common:Customer_other')} />
              {hasUserFeatureFlag(
                FeatureFlags.ADD_CUSTOMER_FLOW,
                featureFlags
              ) && (
                <div>
                  <AddCustomerButtonLink />
                </div>
              )}
            </Container>
            <SearchInput
              searchPlaceholder={t('producer:SearchCustomerList')}
              searchStringInput={searchQuery || ''}
              onSearchChange={({ target: { value } }) => {
                return setQueryValue(QUERY_KEY, value);
              }}
              onResetSearchString={() => {
                return setQueryValue(QUERY_KEY, '');
              }}
            />
            <div className={customerCountContainer()}>
              <span className={customerCount()}>
                {filteredConsumers.length}
              </span>
              <span className={customerCountText()}>
                {' '}
                {t('common:customers')}
              </span>
            </div>
          </Box.FullWidth>
        </>
      }
      noContentPadding
    >
      <ContentLoader req={req}>
        {filteredConsumers.length < 1 ? (
          <CustomerListEmpty
            title={t('producer:CustomerListNoResultsTitle')}
            description={t('producer:CustomerListNoResultsDescription')}
          />
        ) : desktopUp ? (
          <CustomerTable
            consumers={filteredConsumers}
            activeConsumer={activeConsumer}
            onConsumerClick={consumer => setActiveConsumer(consumer)}
            onModalClose={() => setActiveConsumer(null)}
          />
        ) : (
          <CustomerList
            consumers={filteredConsumers}
            activeConsumer={activeConsumer}
            onConsumerClick={consumer => setActiveConsumer(consumer)}
            onModalClose={() => setActiveConsumer(null)}
          />
        )}
      </ContentLoader>
    </Page>
  );
};
