import { Button, PriceCard } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useFormContext, useWatch } from '@dagens/frontend-forms';
import { UnitUtils } from '@dagens/utils';
import {
  EDIT_PRODUCT_CLICK_ADD_NEW_OFFER,
  track
} from '../../../../utils/mixpanel';
import { ProductFormPricesValues } from '../edit';
import { Pricing } from '../../../../types/Product';
import { ProductFormValues } from '..';
import { ProductFormPricesCard } from './prices-card';

type Props = {
  productId?: string;
};

export const ProductFormPrices = ({ productId }: Props) => {
  const { t } = useTranslation();
  const { register } = useFormContext<
    ProductFormValues & ProductFormPricesValues
  >();

  // When editing a product, check if the product has valid prices
  // If any of the prices is missing a weight, prevent the user from submitting the form
  register<'prices'>('prices', {
    validate: (value, data) => {
      const isFoodProduct = !data.category?.some(c => c.isNonFood);
      const everyPriceHasWeight = value.every(price => {
        const weightIsRequired =
          isFoodProduct && !UnitUtils.isMeasureUnit(price.pricedUnit);
        const valid =
          !weightIsRequired ||
          (weightIsRequired &&
            price.inputMeasureUnit &&
            price.inputMeasureUnitValue);
        return valid;
      });
      return everyPriceHasWeight
        ? undefined
        : t('producer:productInputMissingRequiredWeight');
    }
  });

  const prices = useWatch<ProductFormPricesValues>({
    name: 'prices'
  }) as Pricing[];

  if (!productId) {
    return null;
  }

  return (
    <PriceCard.Container>
      {prices?.map(price => (
        <ProductFormPricesCard
          key={price._key}
          productId={productId}
          price={price}
        />
      ))}
      <div>
        <Button
          as="link"
          variant="ghost"
          to={`/my-products/${productId}/add-pricing`}
          onClick={() => {
            track(EDIT_PRODUCT_CLICK_ADD_NEW_OFFER);
          }}
        >
          {t('producer:AddNewPricing')}
        </Button>
      </div>
    </PriceCard.Container>
  );
};
