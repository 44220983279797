import { FieldValues, Path, PathValue } from 'react-hook-form';
import { TreeValue } from '@dagens/carrot';
import { MatchingKeys } from '../types/fields';
import { ImageFile } from '../types/image-file';
import { Checkboxes, CheckboxesProps } from './checkboxes';
import { ComboboxMulti, ComboboxMultiProps } from './combobox-multi';
import { Textarea, TextareaProps } from './textarea';
import { CheckboxesTree, CheckboxesTreeProps } from './checkboxes-tree';
import { PeriodPicker, PeriodPickerProps } from './period-picker';
import { PeriodPickerValue } from './period-picker/use-values';
import { Radio, RadioProps } from './radio';
import { Input, InputProps } from './input';
import { ImageInput, ImageInputProps } from './image-input';
import { Select, SelectProps } from './select';
import { NumberInput, NumberInputProps } from './number-input';
import { FormField, FormFieldProps } from './field';

// Used to create components that have the first generic argument set to the form values type,
// so that the components are type-safe without having to specify the form values type every time.
export const formFieldFactory = <Form extends FieldValues>() => {
  const TypedField = <Name extends Path<Form>>(
    props: FormFieldProps<Form, Name>
  ) => {
    return <FormField<Form, Name> {...props} />;
  };

  const TypedPeriodPicker = <
    Name extends MatchingKeys<Form, PeriodPickerValue>
  >(
    props: PeriodPickerProps<Form, Name>
  ) => {
    return <PeriodPicker<Form, Name> {...props} />;
  };

  const TypedCheckboxes = <
    Name extends Path<Form>,
    Value extends PathValue<Form, Name>
  >(
    props: CheckboxesProps<Form, Name, Value>
  ) => {
    return <Checkboxes<Form, Name, Value> {...props} />;
  };

  const TypedComboboxMulti = <
    Name extends Path<Form>,
    Value extends PathValue<Form, Name>
  >(
    props: ComboboxMultiProps<Form, Name, Value>
  ) => {
    return <ComboboxMulti<Form, Name, Value> {...props} />;
  };

  const TypedImageInput = <Name extends MatchingKeys<Form, ImageFile | null>>(
    props: ImageInputProps<Form, Name>
  ) => {
    return <ImageInput {...props} />;
  };

  const TypedInput = <Name extends MatchingKeys<Form, string | null>>(
    props: InputProps<Form, Name>
  ) => {
    return <Input {...props} />;
  };

  const TypedCheckboxesTree = <
    Name extends MatchingKeys<Form, TreeValue<T>[]>,
    T
  >(
    props: CheckboxesTreeProps<Form, Name, T>
  ) => {
    return <CheckboxesTree {...props} />;
  };

  const TypedNumberInput = <Name extends MatchingKeys<Form, number | null>>(
    props: NumberInputProps<Form, Name>
  ) => {
    return <NumberInput {...props} />;
  };

  const TypedRadio = <
    Name extends Path<Form>,
    Value extends PathValue<Form, Name>
  >(
    props: RadioProps<Form, Name, Value>
  ) => {
    return <Radio<Form, Name, Value> {...props} />;
  };

  const TypedSelect = <
    Name extends Path<Form>,
    Value extends PathValue<Form, Name>
  >(
    props: SelectProps<Form, Name, Value>
  ) => {
    return <Select<Form, Name, Value> {...props} />;
  };

  const TypedTextarea = <Name extends MatchingKeys<Form, string | null>>(
    props: TextareaProps<Form, Name>
  ) => {
    return <Textarea {...props} />;
  };

  return {
    Field: TypedField,
    Checkboxes: TypedCheckboxes,
    CheckboxesTree: TypedCheckboxesTree,
    ComboboxMulti: TypedComboboxMulti,
    ImageInput: TypedImageInput,
    Input: TypedInput,
    NumberInput: TypedNumberInput,
    PeriodPicker: TypedPeriodPicker,
    Radio: TypedRadio,
    Select: TypedSelect,
    Textarea: TypedTextarea
  };
};
