import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';

const style = tv({
  base: `text-inputLabel`
});

type Props = PropsWithChildren;

export const FormFieldLabel = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
