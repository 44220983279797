import { tv } from '../../utils/tv';

const style = tv({
  base: `
    flex
    items-center
    justify-center
    py-[12px]
    text-paragraphBold
  `
});

type Props = {
  text?: string;
};

export const NavContext = ({ text }: Props) => {
  return <div className={style()}>{text}</div>;
};
