import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes
} from 'react';
import { tv } from '../../utils/tv';
import { Icon, IconType } from '../../theme/icon';
import { Button } from '../button';
import { inputStyle } from './styles';

const style = tv({
  slots: {
    clearButton: `my-[-10px]`
  }
});

type HTMLInputProps = InputHTMLAttributes<HTMLInputElement>;

type InputTypes = Extract<
  HTMLInputProps['type'],
  'text' | 'email' | 'password' | 'tel'
>;

export type InputProps = {
  type?: InputTypes;
  placeholder?: HTMLInputProps['placeholder'];
  maxLength?: HTMLInputProps['maxLength'];
  error?: boolean;
  iconBefore?: IconType;
  value: string;
  withClear?: boolean;
  onChange: HTMLInputProps['onChange'];
};

const Component = (
  { error, iconBefore, withClear, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { container, input } = inputStyle({ error });
  const { clearButton } = style();

  return (
    <label className={container()}>
      {iconBefore && <Icon icon={iconBefore} />}
      <input {...props} ref={ref} className={input()} />
      {withClear && props.value && (
        <div className={clearButton()}>
          <Button
            size="small"
            variant="borderless"
            onClick={() =>
              props.onChange?.({
                target: { value: '' }
              } as ChangeEvent<HTMLInputElement>)
            }
          >
            <Icon icon="close" size="small" />
          </Button>
        </div>
      )}
    </label>
  );
};

export const Input = forwardRef(Component);
