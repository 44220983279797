import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { createPortal } from 'react-dom';
import { useTranslation } from '@dagens/frontend-i18n';
import { ReqType } from '../../../../utils/REQ';
import { MinimumOrderAmountFormSubmitButton as Submit } from './submit-button';

export type MinimumOrderFormValues = {
  minimumOrderAmount: number | null;
  minimumOrderAmountEnforced: boolean;
};

const { Field, Radio, NumberInput } =
  formFieldFactory<MinimumOrderFormValues>();

type Props = {
  saveReq: ReqType;
  defaultValue?: MinimumOrderFormValues;
  onSubmit: (data: MinimumOrderFormValues) => void;
  submitButtonContainer: HTMLDivElement | null;
};

export const MinimumOrderForm = ({
  saveReq,
  defaultValue,
  onSubmit,
  submitButtonContainer
}: Props) => {
  const { t } = useTranslation();

  return (
    <Form<MinimumOrderFormValues>
      defaultValue={defaultValue}
      onSubmit={onSubmit}
    >
      <Form.Section>
        <Field
          to="minimumOrderAmount"
          label={t('producer:MinimumOrderAmountLabelText')}
          helpText={t('producer:MinimumOrderAmountHelperText')}
        >
          <NumberInput
            name="minimumOrderAmount"
            unit={t('producer:MinimumOrderAmountUnit')}
            small
          />
        </Field>
        <Field
          to="minimumOrderAmountEnforced"
          label={t('producer:moaStrictMinimum')}
          required
        >
          <Radio
            name="minimumOrderAmountEnforced"
            options={[true, false]}
            displayValue={value =>
              value
                ? {
                    label: t('producer:moaStrict'),
                    description: t('producer:moaStrictDescription')
                  }
                : {
                    label: t('producer:moaFlexible'),
                    description: t('producer:moaFlexibleDescription')
                  }
            }
          />
        </Field>
      </Form.Section>

      {submitButtonContainer &&
        createPortal(
          <Submit saveReq={saveReq} onSubmit={onSubmit} />,
          submitButtonContainer
        )}
    </Form>
  );
};
