import { Box } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { SelectedDeliveryDate } from '../../../../_common/components/delivery-date-picker/DeliveryDatePicker';
import DeliveryTypeTag from '../../../../_common/components/tags/DeliveryTypeTag';
import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';
import { FormField } from '../../../../carrot-deprecated/form-field';
import { FormFieldType } from '../../../../carrot-deprecated/form-field/types';
import { CommonNavbar } from '../../../../components/navbar';
import Logger from '../../../../services/Logger';
import { DeliveryType } from '../../../../types/Logistics';
import { OrderLine } from '../../../../types/Order';
import { ProductWithUnit } from '../../add-orderline/ProductList';
import { checkDeliveryRouteReady } from './check-delivery-route-ready';
import { trackOrderFlowPlaceOrder } from './track-order-flow';
import { useGenerateOrderLinesFromProducts } from './useGenerateOrderLinesFromProducts';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import REQ, { ReqType } from 'utils/REQ';
import { formatDate, slugifyDate } from 'utils/date/format';
import { ProductForSale } from 'types/Product';
import { Consumer } from 'types/Consumer';
import { postOrder, PostOrderResponse } from 'api';
import { Page } from '@components/page';
import { useAppSelector } from '_common/hooks/reduxHooks';
import PageHeader from '@components/page-header/PageHeader';
import OrderLineTable from '_common/components/orderline-table/OrderLineTable';
import Button, { ButtonLink } from '_common/components/button/Button.style';

type LocationState =
  | {
      consumer: Consumer;
      deliveryDates: SelectedDeliveryDate[];
      products: ProductWithUnit[];
    }
  | undefined;

const AddOrderCheckoutPage = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const selectedConsumer = state?.consumer;
  const selectedDeliveryDates = state?.deliveryDates ?? [];
  const selectedProducts = state?.products ?? [];

  const [submitReq, setSubmitReq] = useState<ReqType>(REQ.INIT);
  const [createdOrderResponse, setCreatedOrderResponse] =
    useState<PostOrderResponse | null>(null);
  const [ordersByDeliveryDays, setOrdersByDeliveryDays] = useState<
    {
      deliveryDate: string;
      withDagensLogistics: boolean;
      orderReference: string;
      messageFromProducerToConsumer: string;
      consumerId?: string;
      consumerName?: string;
      orderLines: OrderLine[];
    }[]
  >([]);
  const { roleId, userId, products } = useAppSelector(
    ({ auth, producerProducts: { items } }) => {
      return {
        roleId: auth._id,
        userId: auth.uid,
        products: items as unknown as ProductForSale[] // We need to clean up in Product-type, we can't have two who gets used around each other
      };
    }
  );
  const generateOrderLinesFromSelectedProducts =
    useGenerateOrderLinesFromProducts({
      products
    });
  const { t } = useTranslation();
  const { tabletUp } = useMediaQuery();
  const navigate = useNavigate();

  const placeOrders = async () => {
    const orderPayloadsByDeliveryDate = ordersByDeliveryDays.map(
      ({
        deliveryDate,
        withDagensLogistics,
        orderReference,
        messageFromProducerToConsumer,
        consumerId,
        orderLines
      }) => {
        return {
          basket: orderLines.map(({ _key, nrOfUnits }) => {
            return {
              productId: _key,
              unitsInBasket: nrOfUnits
            };
          }),
          deliveryDate: slugifyDate(deliveryDate),
          consumerId,
          createdBy: userId,
          createdAs: roleId,
          messageFromProducerToConsumer,
          withLogistics: withDagensLogistics,
          orderReference
        };
      }
    );
    setSubmitReq(REQ.PENDING);
    try {
      Logger.info(
        `Producer with id ${roleId} creating ${ordersByDeliveryDays.length} order(s) for ${selectedConsumer?.name}`
      );
      const createdOrdersResponse = await Promise.all(
        orderPayloadsByDeliveryDate.map(async ordersByDeliveryDate => {
          Logger.debug(
            `Creating order for delivery date ${ordersByDeliveryDate.deliveryDate}`
          );
          const postOrderRes = await postOrder(ordersByDeliveryDate);
          trackOrderFlowPlaceOrder(
            generateOrderLinesFromSelectedProducts(
              selectedProducts,
              selectedConsumer?._id
            ),
            selectedProducts,
            selectedConsumer?._id,
            selectedConsumer?.name,
            products
          );
          return postOrderRes;
        })
      );
      const createdOrdersResponseFlatten = createdOrdersResponse.flat();
      setCreatedOrderResponse(createdOrdersResponseFlatten);
      await Promise.all(
        await createdOrdersResponseFlatten.map(async ({ orderId }) => {
          await checkDeliveryRouteReady(orderId, 0);
        })
      );
      setSubmitReq(REQ.SUCCESS);
    } catch (error) {
      setSubmitReq(REQ.ERROR);
    }
  };

  const handleMessageFromProducerToConsumerChange = (
    deliveryDate: string,
    message: string
  ) => {
    const updatedOrdersByDeliveryDays = ordersByDeliveryDays.map(
      orderByDeliveryDay => {
        if (orderByDeliveryDay.deliveryDate === deliveryDate) {
          return {
            ...orderByDeliveryDay,
            messageFromProducerToConsumer: message
          };
        }
        return orderByDeliveryDay;
      }
    );
    setOrdersByDeliveryDays(updatedOrdersByDeliveryDays);
  };

  const handleOrderReferenceChange = (
    deliveryDate: string,
    reference: string
  ) => {
    const updatedOrdersByDeliveryDays = ordersByDeliveryDays.map(
      orderByDeliveryDay => {
        if (orderByDeliveryDay.deliveryDate === deliveryDate) {
          return {
            ...orderByDeliveryDay,
            orderReference: reference
          };
        }
        return orderByDeliveryDay;
      }
    );
    setOrdersByDeliveryDays(updatedOrdersByDeliveryDays);
  };

  useEffect(() => {
    const ordersByDeliveryDaysGenerated = selectedDeliveryDates.map(
      selectedDeliveryDate => {
        return {
          deliveryDate: slugifyDate(selectedDeliveryDate.deliveryDate),
          withDagensLogistics: selectedDeliveryDate.withLogistics,
          orderReference: '',
          messageFromProducerToConsumer: '',
          consumerId: selectedConsumer?._id,
          consumerName: selectedConsumer?.name,
          orderLines: generateOrderLinesFromSelectedProducts(
            selectedProducts,
            selectedConsumer?._id
          )
        };
      }
    );
    setOrdersByDeliveryDays(ordersByDeliveryDaysGenerated);
  }, []);

  if (
    !selectedConsumer ||
    selectedDeliveryDates.length === 0 ||
    !selectedProducts
  ) {
    return <Navigate to="/orders/add/start" />;
  }

  if (submitReq === REQ.SUCCESS) {
    setTimeout(() => {
      if (createdOrderResponse?.length === 1) {
        navigate(`/orders/${createdOrderResponse[0].secret}`);
      } else {
        navigate('/orders');
      }
    }, 3000);
  }

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t('producer:ConfirmTheOrder')}
              subTitle={`${t('common:to')} ${selectedConsumer.name}, ${
                selectedDeliveryDates.length > 1
                  ? `${ordersByDeliveryDays.length} ${t('common:order', {
                      count: ordersByDeliveryDays.length
                    })}`
                  : formatDate(selectedDeliveryDates[0].deliveryDate)
              }`}
              annotation={`${t('common:MakeNewOrder')} (4/4)`}
            />
          </Box.FullWidth>
        </>
      }
      bottom={
        <Box.BottomSheet border>
          {(submitReq === REQ.INIT || submitReq === REQ.ERROR) && (
            <>
              <ButtonLink
                to="/orders/add/products"
                state={{
                  consumer: selectedConsumer,
                  deliveryDates: selectedDeliveryDates,
                  products: selectedProducts
                }}
              >
                {t('common:BackWithArrow')}
              </ButtonLink>
              <Button
                onClick={() => {
                  placeOrders();
                }}
                variant="primary"
              >
                {t('producer:PlaceTheOrder', {
                  count: ordersByDeliveryDays.length
                })}
              </Button>
            </>
          )}
          {submitReq === REQ.PENDING && (
            <Typography variant="paragraph" color="secondary">
              {t('producer:PlacingTheOrder', {
                count: ordersByDeliveryDays.length
              })}
            </Typography>
          )}
          {submitReq === REQ.SUCCESS && (
            <Typography variant="paragraph" color="secondary">
              {t('producer:CreateNewOrderOrderCreatedFeedback', {
                count: ordersByDeliveryDays.length
              })}
            </Typography>
          )}
        </Box.BottomSheet>
      }
    >
      <Container mt="none" mb="m">
        <Typography>
          {t('producer:CreateNewOrderCalloutText', {
            count: ordersByDeliveryDays.length
          })}
        </Typography>
      </Container>
      {ordersByDeliveryDays.map(
        ({
          deliveryDate,
          withDagensLogistics,
          orderLines,
          messageFromProducerToConsumer,
          orderReference
        }) => {
          return (
            <Container key={deliveryDate} mb="xl">
              <Container
                flex
                mb="s"
                gap="xs"
                justify={tabletUp ? 'flex-end' : 'flex-start'}
                alignItems={tabletUp ? 'center' : 'flex-start'}
                direction={tabletUp ? 'row-reverse' : 'column'}
              >
                <DeliveryTypeTag
                  deliveryType={
                    withDagensLogistics
                      ? DeliveryType.DAGENS_DELIVERY
                      : DeliveryType.SELF_DELIVERY
                  }
                />
                <Typography variant="secondaryHeading" my="none">
                  {capitalize(formatDate(deliveryDate))}
                </Typography>
              </Container>
              <OrderLineTable
                orderId="no-id-yet"
                orderLines={orderLines}
                isReadOnly
                hideTableHeader
              />
              <Container my="m" flex gap="s">
                <FormField
                  type={FormFieldType.TEXT}
                  label={t('producer:MessageToCustomer')}
                  helpText={t('producer:WriteMessageToTheCustomer')}
                  optional
                  inputProps={{
                    value: messageFromProducerToConsumer,
                    onChange: ({ target: { value } }) => {
                      handleMessageFromProducerToConsumerChange(
                        deliveryDate,
                        value
                      );
                    }
                  }}
                />
                <FormField
                  type={FormFieldType.TEXT}
                  label={t('common:OrderReference')}
                  helpText={t('common:OrderReferenceInputHelpText')}
                  optional
                  inputProps={{
                    value: orderReference,
                    onChange: ({ target: { value } }) => {
                      handleOrderReferenceChange(deliveryDate, value);
                    }
                  }}
                />
              </Container>
            </Container>
          );
        }
      )}
    </Page>
  );
};

export default AddOrderCheckoutPage;
