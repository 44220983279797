import { forwardRef, PropsWithChildren, Ref } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';
import { fullWidthStyle } from './full-width';

const style = tv({
  base: `
    flex
    justify-between
    py-s
    pb-m
  `,
  variants: {
    border: {
      true: `
        border-t
        border-t-secondary
      `
    }
  }
});

type Props = PropsWithChildren<{
  border?: boolean;
}>;

const Component = ({ border, children }: Props, ref: Ref<HTMLDivElement>) => {
  return (
    <div className={twMerge(fullWidthStyle(), style({ border }))} ref={ref}>
      {children}
    </div>
  );
};

export const BottomSheet = forwardRef(Component);
