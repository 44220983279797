import { Button, Box } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { isEmpty } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { DeliveryType, Hub } from '../../../types/Logistics';
import { useThrowError } from '../../../utils/errorHandling';
import REQ, { ReqType } from '../../../utils/REQ';
import { onSubmit } from './shared';
import { Page } from '@components/page';
import TextArea from '_common/components/text-area/TextArea.style';
import PageHeader from '@components/page-header/PageHeader';
import FormLabel from '_common/components/input/FormLabel';

type LocationState =
  | {
      deliveryType: DeliveryType | undefined;
      distributionAreaId: string | null | undefined;
      hubsByCountry: Hub[];
    }
  | undefined;

const DeliveryRouteRequestSelfDelivery = () => {
  const [textValue, setTextValue] = useState<string>('');
  const [submitReq, setSubmitReq] = useState<ReqType>(REQ.INIT);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const throwAsyncError = useThrowError();

  const location = useLocation();
  const state = location.state as LocationState;
  const { deliveryType, hubsByCountry } = state ?? {};

  const onChange = ({
    target: { value }
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(value);
  };

  const { roleId } = useAppSelector(({ auth }) => {
    return {
      roleId: auth._id
    };
  });

  if (!deliveryType) {
    return <Navigate to="/profile/delivery-route-request/start" />;
  }

  return (
    <Page
      bottom={
        <Box.BottomSheet>
          <Button
            variant="primary"
            size="fullWidth"
            disabled={isEmpty(textValue) || submitReq === REQ.PENDING}
            onClick={async () => {
              setSubmitReq(REQ.PENDING);
              const { req, data } = await onSubmit({
                deliveryType,
                hubsByCountry: hubsByCountry ?? [],
                roleId,
                textValue
              });
              if (req === REQ.SUCCESS) {
                navigate('/profile/delivery-route-request/complete', {
                  state: { details: data.details }
                });
              } else {
                throwAsyncError(
                  new Error(
                    `Could not submit delivery route request for user id: ${roleId}`
                  )
                );
              }
            }}
          >
            {t('producer:DeliveryRouteRequest.NextButtonHub')}
          </Button>
        </Box.BottomSheet>
      }
    >
      <PageHeader
        headerText={t('producer:DeliveryRouteRequest.PageHeader')}
        subTitle={t('producer:DeliveryRouteRequest.SelfDeliveryOptionSubTitle')}
      />
      <FormLabel
        labelText={t(
          'producer:DeliveryRouteRequest.SelfDeliveryOptionFormLabelTitle'
        )}
        helpText={t(
          'producer:DeliveryRouteRequest.SelfDeliveryOptionFormLabelHelpText'
        )}
      />
      <TextArea
        placeholder={t(
          'producer:DeliveryRouteRequest.SelfDeliveryOptionTextAreaPlaceholder'
        )}
        onChange={onChange}
        value={textValue}
        maxLength={150}
        height="200px"
      />
    </Page>
  );
};

export default DeliveryRouteRequestSelfDelivery;
