import { Form } from '@dagens/frontend-forms';
import { createPortal } from 'react-dom';
import { useState } from 'react';
import { ReqType } from '../../../utils/REQ';
import { Pricing, ProducerProduct } from '../../../types/Product';
import { ProductFormDeleteButton as Delete } from './parts/delete-button';
import { ProductFormSubmitButton as Submit } from './parts/submit-button';
import { ProductFormFields as Fields } from './parts/fields';
import { ProductEditFormPageTitle as PageTitle } from './parts/title';
import { ProductFormValues } from '.';

export type ProductFormPricesValues = {
  prices: Pricing[];
};

type Props = {
  product: ProducerProduct;
  saveReq: ReqType;
  defaultValue?: ProductFormValues & ProductFormPricesValues;
  onDelete: () => void;
  onSubmit: (data: ProductFormValues & ProductFormPricesValues) => void;
  submitButtonContainer: HTMLDivElement | null;
  titleContainer: HTMLSpanElement | null;
};

// The prices are edited in a separate form in when editing a product
export const ProductEditForm = ({
  product,
  saveReq,
  defaultValue,
  onDelete,
  onSubmit,
  submitButtonContainer,
  titleContainer
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    onDelete();
  };

  return (
    <Form<ProductFormValues & ProductFormPricesValues>
      defaultValue={defaultValue}
      onSubmit={onSubmit}
    >
      {/* The page title changes with the product name, so it needs the form context */}
      {titleContainer &&
        createPortal(
          <PageTitle defaultValue={defaultValue?.name} />,
          titleContainer
        )}

      <Fields flow="edit" defaultValue={defaultValue} product={product} />

      <Delete onDelete={handleDelete} />

      {/* The submit button is rendered in the footer, but it needs the form context */}
      {submitButtonContainer &&
        !isDeleting &&
        createPortal(
          <Submit flow="edit" saveReq={saveReq} onSubmit={onSubmit} />,
          submitButtonContainer
        )}
    </Form>
  );
};
