import { useTranslation } from '@dagens/frontend-i18n';
import { useNavigate } from 'react-router-dom';
import { DeliveryWindow } from '../../../types/Consumer';
import FormattedDeliveryInformation from './FormattedDeliveryInformation';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';

import Icon from '_common/components/utils/Icon';
import Button from '_common/components/button/Button.style';

type IEditDeliveryInformationButton = {
  deliveryAddress?: string;
  deliveryInfo?: string;
  deliveryWindow?: DeliveryWindow;
  redirectTo?: string;
  showHeader?: boolean;
};

const EditDeliveryInformationRedirector = ({
  deliveryAddress,
  deliveryInfo,
  deliveryWindow,
  redirectTo = '/profile',
  showHeader = true
}: IEditDeliveryInformationButton) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Container>
      {showHeader && (
        <Typography variant="secondaryHeading" mb="s">
          {t('common:DeliveryInformation')}
        </Typography>
      )}
      <FormattedDeliveryInformation
        deliveryAddress={deliveryAddress}
        deliveryWindow={deliveryWindow}
        deliveryInfo={deliveryInfo}
      />
      {!deliveryInfo && (
        <Typography color="secondary" variant="paragraphSmall">
          {t('consumer:AddSpecificDeliveryInstructionsIfNeeded')}
        </Typography>
      )}
      <Button
        variant="ghost"
        mt={Theme.Spacings.s}
        onClick={() => {
          navigate('/delivery-information', {
            state: { redirect: redirectTo }
          });
        }}
      >
        <Icon icon="editSmall" mr={Theme.Spacings.xs} />
        {t('consumer:EditDeliveryInfo')}
      </Button>
    </Container>
  );
};
export default EditDeliveryInformationRedirector;
