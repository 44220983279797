import { tv } from '../../utils/tv';
import { ThemeColor } from '../../theme/colors';

const style = tv({
  base: `
    text-allCaps
    inline-block
    rounded-[3px]
    p-xxs
  `,
  variants: {
    color: {
      availability: `bg-availability`,
      noteworthy: `bg-noteworthy`,
      warning: `bg-warning`,
      messages: `bg-messages`,
      error: `bg-error`
    }
  }
});

type Props = {
  text: string;
  color: Extract<
    ThemeColor,
    'availability' | 'noteworthy' | 'warning' | 'messages' | 'error'
  >;
};

export const Tag = ({ text, color }: Props) => {
  return <div className={style({ color })}>{text}</div>;
};
