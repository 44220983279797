import { Button, Icon, PriceCard } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { OrderUnit, UnitUtils } from '@dagens/utils';
import { useFormContext, useWatch } from '@dagens/frontend-forms';
import { truncate } from '../../../../utils/array';
import { Category, Pricing } from '../../../../types/Product';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { usePricingToString } from '../../pricing-form/hooks/use-pricing-to-string';
import {
  EDIT_PRODUCT_CLICK_CONFIRM_TO_DELETE_OFFER,
  EDIT_PRODUCT_CLICK_EDIT_OFFER,
  track
} from '../../../../utils/mixpanel';
import { ProductFormValues } from '..';
import { ProductFormPricesValues } from '../edit';

// Resolves if the price is for all customers or for specific ones
const useIsCustomOffer = (price: Pricing) => {
  const { consumers } = useAppSelector(({ consumers: { items = [] } }) => {
    return { consumers: items };
  });

  const specialConsumersWithNames = price.specialConsumers?.map(consumer => {
    const consumerWithNames = consumers.find(c => c._id === consumer._ref);
    return consumerWithNames;
  });

  const isCustomOffer =
    specialConsumersWithNames && specialConsumersWithNames.length > 0;

  return {
    isCustomOffer: isCustomOffer ?? false,
    customerNames:
      specialConsumersWithNames
        ?.map(c => c?.name)
        .filter(c => c !== undefined) ?? []
  };
};

// Resolves the title of the price card – the customers the price is for
const useTitle = (isCustomOffer: boolean, customerNames: string[]) => {
  const { t } = useTranslation();
  return isCustomOffer
    ? truncate({
        list: customerNames,
        numToShow: 1,
        addendum: t('common:more')
      })
    : t('common:AllBuyers');
};

// Resolves if the price is valid based on the product category and its values/units
const useIsValidPrice = (price: Pricing) => {
  const category = useWatch<ProductFormValues>({
    name: 'category'
  }) as Category[] | undefined;
  const isFoodProduct = !category?.some(c => c.isNonFood);
  const weightIsRequired =
    isFoodProduct && !UnitUtils.isMeasureUnit(price.pricedUnit);
  return (
    !weightIsRequired ||
    (weightIsRequired && price.inputMeasureUnit && price.inputMeasureUnitValue)
  );
};

type Props = {
  productId: string;
  price: Pricing;
};

// Shows information about the price of a product
// Allows the user to edit the price (redirects to the pricing form)
// Allows the user to delete the price – updates the forms values
// If the product only has one price, the delete button is hidden
export const ProductFormPricesCard = ({ productId, price }: Props) => {
  const { t } = useTranslation();
  const { isCustomOffer, customerNames } = useIsCustomOffer(price);
  const { getValues, setValue } = useFormContext<
    ProductFormValues & ProductFormPricesValues
  >();
  const prices = useWatch<ProductFormValues & ProductFormPricesValues>({
    name: 'prices'
  }) as Pricing[];
  const valid = useIsValidPrice(price);

  const req = useAppSelector(({ consumers: { req } }) => req);

  const title = useTitle(isCustomOffer, customerNames);
  const tag = isCustomOffer ? t('common:CustomOffer') : undefined;
  const value = usePricingToString(
    price.nokPerPricedUnit,
    price.pricedUnit,
    price.pricedUnitsPerOrderedUnit,
    price.orderedUnit as OrderUnit,
    price.pricedUnit === price.orderedUnit
  );

  const onDelete = () => {
    track(EDIT_PRODUCT_CLICK_CONFIRM_TO_DELETE_OFFER);
    const prices = getValues('prices');
    setValue(
      'prices',
      prices.filter(p => p._key !== price._key)
    );
  };

  if (req !== 'SUCCESS') {
    return null;
  }

  return (
    <PriceCard
      title={title}
      tag={tag}
      value={value ?? ''}
      error={
        !valid ? t('producer:productInputMissingRequiredWeight') : undefined
      }
      actions={
        <>
          <Button
            as="link"
            variant="borderless"
            size="small"
            to={`/my-products/${productId}/edit-pricing/${price._key}`}
            onClick={() => track(EDIT_PRODUCT_CLICK_EDIT_OFFER)}
          >
            <Icon icon="edit" size="small" />
            {t('common:Edit')}
          </Button>
          {prices.length > 1 && (
            <Button variant="borderless" onClick={onDelete} size="small">
              <Icon icon="delete" size="small" />
              {t('common:Delete')}
            </Button>
          )}
        </>
      }
    />
  );
};
