import { tv } from '../../../utils/tv';

import {
  NavLinkComponent,
  NavLinkComponentProps
} from '../components/as-nav-link';

const style = tv({
  base: `
    size-auto
    p-xs
    !text-paragraph
    text-secondary
  `,
  variants: {
    mobile: {
      true: `
        size-full
        rounded-none
        !text-microText
      `
    },
    isActive: {
      true: 'text-black'
    }
  }
});

const contentStyle = tv({
  base: 'contents',
  variants: {
    vertical: {
      true: `
        flex
        size-full
        flex-col
        items-center
      `
    }
  }
});

const badgeStyle = tv({
  base: `
    absolute
    -top-xxs
    left-full
    -ml-xs
    -mt-xxs
  `,
  variants: {
    mobile: {
      true: `
        left-1/2
        ml-0
        mt-xxs
      `
    }
  }
});

const underlineStyle = tv({
  base: `
    absolute
    inset-x-0
    bottom-[-2px]
    top-0

    group-[.active]:border-b
    group-[.active]:border-currentColor
  `
});

type Props = Omit<NavLinkComponentProps, 'styles' | 'className' | 'as'> & {
  mobile?: boolean;
  badge?: React.ReactNode;
};

export const NavLink = ({ mobile, badge, children, ...props }: Props) => {
  return (
    <NavLinkComponent
      {...props}
      as="nav-link"
      variant="borderless"
      size="regular"
      className={({ isActive }) => style({ isActive, mobile })}
    >
      <div className={contentStyle({ vertical: mobile })}>{children}</div>
      <div className={badgeStyle({ mobile })}>{badge}</div>
      {!mobile && <div className={underlineStyle()} />}
    </NavLinkComponent>
  );
};
