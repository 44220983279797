import { MeasureUnit, OrderUnit, PriceUnit } from '@dagens/utils';
import { Form } from '@dagens/frontend-forms';
import { createPortal } from 'react-dom';
import { Pricing } from '../../../types/Product';
import { ReqType } from '../../../utils/REQ';
import { ConsumersWithChildren } from './hooks/use-consumers';
import { PricingFormSubmitButton as Submit } from './parts/submit-button';
import { PricingFormFields as Fields } from './parts/fields';

type Props = {
  saveReq: ReqType;
  defaultValue?: PricingFormValues;
  productCategoryId?: string;
  otherPrices?: Pricing[];
  onSubmit: (data: PricingFormValues) => void;
  submitButtonContainer: HTMLDivElement | null;
};

export type PricingFormValues = {
  customers: ConsumersWithChildren[];
  priceValue: number | null;
  priceUnit: PriceUnit;
  measureValue: number | null;
  measureUnit: MeasureUnit | null;
  orderEqualsPrice: boolean;
  orderValue: number | null;
  orderUnit: OrderUnit | null;
  additionalInfo: string | null;
};

export const PricingForm = ({
  saveReq,
  defaultValue,
  productCategoryId,
  otherPrices,
  onSubmit,
  submitButtonContainer
}: Props) => {
  return (
    <Form<PricingFormValues> defaultValue={defaultValue} onSubmit={onSubmit}>
      <Fields
        defaultValue={defaultValue}
        otherPrices={otherPrices}
        categoryId={productCategoryId}
      />

      {submitButtonContainer &&
        createPortal(
          <Submit saveReq={saveReq} onSubmit={onSubmit} />,
          submitButtonContainer
        )}
    </Form>
  );
};
