import {
  FormField as CarrotFormField,
  Form as CarrotForm
} from '@dagens/carrot';
import { FormField } from '../fields/field';
import { Form as FormComponent } from './form';

export const Form = Object.assign(FormComponent, {
  Field: FormField,
  Group: CarrotFormField.Group,
  Section: CarrotForm.Section,
  Container: CarrotForm.Container
});
