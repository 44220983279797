import { ReactNode } from 'react';
import { tv } from '../utils/tv';

const style = tv({
  base: `
    relative
    grid
    h-full
    grid-cols-1
    grid-rows-[auto,1fr,auto]
    overflow-hidden

    print:overflow-visible
  `,
  slots: {
    content: `
      overflow-y-auto
      overflow-x-hidden
    `
  },
  variants: {
    withPadding: {
      true: { content: 'py-s' }
    }
  }
});

type Props = {
  header: ReactNode;
  content: ReactNode;
  bottom?: ReactNode;
  modal?: ReactNode;
  noContentPadding?: boolean;
};

export const LayoutPage = ({
  header,
  content,
  bottom,
  modal,
  noContentPadding
}: Props) => {
  const { base, content: contentStyle } = style();
  return (
    <div className={base()}>
      <header>{header}</header>
      <main className={contentStyle({ withPadding: !noContentPadding })}>
        {content}
      </main>
      {bottom && <nav>{bottom}</nav>}
      {modal}
    </div>
  );
};
