import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { ByComparator, ComparisonUtils } from '@dagens/utils';
import { ComboboxContainer } from '../combobox/parts/container';
import { ComboboxCustomOptionsMulti } from '../combobox/parts/options';
import { TreeValue } from '../../utils/tree-value';
import { ComboboxInputMulti } from './parts/input';
import { ComboboxChips } from './parts/chips';
import { useComboboxState } from './use-state';

const isAncestor = <T, V extends TreeValue<T>>(
  target: V,
  value: V,
  by?: ByComparator<V>
): boolean => {
  if (ComparisonUtils.equal(by, target, value)) {
    return true;
  }
  return value.children?.some(x => isAncestor(target, x as V, by)) ?? false;
};

export type ComboboxMultiProps<T> = {
  placeholder?: string;
  error?: boolean;
  options: TreeValue<T>[];
  disabledOptions?: TreeValue<T>[];
  displayValue?: (value?: TreeValue<T>) => string | undefined;
  by?: ByComparator<TreeValue<T>>;

  value: TreeValue<T>[];
  onChange: (value: TreeValue<T>[]) => void;
};

export const ComboboxMulti = <T,>({
  error,
  placeholder,
  value,
  options,
  disabledOptions,
  onChange,
  displayValue,
  by
}: ComboboxMultiProps<T>) => {
  const {
    inputId,
    query,
    setQuery,
    valueToString,
    onChangeInternal,
    resetQuery
  } = useComboboxState<TreeValue<T>[]>({
    displayValue,
    onChange
  });

  const onChangeValue = (v: TreeValue<T>[]) => {
    const lastValue = v.at(-1);
    const newValue = v.filter(
      item =>
        lastValue &&
        !isAncestor(lastValue, item, by) &&
        !isAncestor(item, lastValue, by)
    );
    onChangeInternal(lastValue ? [...newValue, lastValue] : newValue);
  };

  const removeValue = (v?: TreeValue<T>) => {
    const newValues = value?.filter(x => !ComparisonUtils.equal(by, x, v));
    onChangeInternal(newValues ?? []);
  };

  const removeLastValue = () => {
    const newValues = value?.slice(0, -1);
    // When removing a chip using backspace, we want to keep the query as is
    onChange?.(newValues ?? []);
  };

  return (
    <HeadlessCombobox
      immediate
      multiple
      value={value}
      onChange={onChangeValue}
      onClose={resetQuery}
      by={by}
    >
      {({ open }) => (
        <div className="relative">
          <ComboboxContainer inputId={inputId} error={error} open={open}>
            <ComboboxChips
              value={value}
              valueToString={valueToString}
              onChipClick={removeValue}
            />
            <ComboboxInputMulti
              inputId={inputId}
              placeholder={placeholder}
              error={error}
              query={query}
              value={value}
              setQuery={setQuery}
              onBackspaceChip={removeLastValue}
            />
          </ComboboxContainer>
          <ComboboxCustomOptionsMulti
            options={options}
            disabledOptions={disabledOptions}
            value={value}
            valueToString={valueToString}
            by={by}
            query={query}
          />
        </div>
      )}
    </HeadlessCombobox>
  );
};
