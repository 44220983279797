import { createTV } from 'tailwind-variants';
import { twMergeFontSizeClassGroup } from '../theme/typography';
import { twMergeTextColorClassGroup } from '../theme/colors';

export const tv = createTV({
  twMergeConfig: {
    override: {
      classGroups: {
        ...twMergeFontSizeClassGroup,
        ...twMergeTextColorClassGroup
      }
    }
  }
});
