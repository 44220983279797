import { tv } from '../../utils/tv';
import { ThemeAnimation } from '../animations';
import { ThemeColor, themeColors } from '../colors';
import { actionIcons } from './action-icons';
import { dagensShapesIcons } from './dagens-shapes-icons';
import { informationIcons } from './information-icons';
import { navigationIcons } from './navigation-icons';
import { IconDefinition, IconSize } from './types';

const style = tv({
  base: `
    pointer-events-none
    relative
    box-content
    inline-flex
    items-end
    justify-center
    align-bottom
  `,
  variants: {
    size: {
      small: `size-s`,
      medium: `size-m`,
      large: `size-l`
    }
  }
});

const iconStyle = tv({
  base: `
    absolute
    inset-0
    size-full
  `,
  variants: {
    animation: {
      heartbeat: 'animate-heartbeat'
    }
  }
});

export const iconTypes = {
  ...navigationIcons,
  ...actionIcons,
  ...dagensShapesIcons,
  ...informationIcons
} as const;

export type IconType = keyof typeof iconTypes;

type Props = {
  animation?: ThemeAnimation;
  icon: IconType;
  size?: IconSize;
  color?: ThemeColor;
};

export const Icon = ({ animation, icon, size = 'medium', color }: Props) => {
  const iconType = iconTypes[icon] as IconDefinition;
  const IconSVG =
    size in iconType
      ? iconType[size]
      : (iconType['medium'] ?? iconType['large'] ?? iconType['small']);
  if (!IconSVG) {
    return null;
  }
  return (
    <div className={style({ size })}>
      <IconSVG
        color={color && themeColors[color]}
        className={iconStyle({ animation })}
      />
    </div>
  );
};
