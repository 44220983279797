import {
  NumberInput as CarrotNumberInput,
  type NumberInputProps as CarrotNumberProps
} from '@dagens/carrot';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from '@dagens/frontend-i18n';
import { MatchingKeys } from '../types/fields';
import { FieldProps, useField } from '../utils/use-field';
import { validationResolver } from '../utils/validation';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'error' | 'value' | 'onChange';

export type NumberInputProps<
  Form extends FieldValues,
  Name extends MatchingKeys<Form, number | null>
> = Omit<CarrotNumberProps, ExcludedFields> & FieldProps<Form, Name>;

export const NumberInput = <
  Form extends FieldValues,
  Name extends MatchingKeys<Form, string | null>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  ...props
}: NumberInputProps<Form, Name>) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<Form>();
  const {
    field,
    fieldState: { error }
  } = useField({
    name,
    required,
    validate: v => {
      if (typeof v === 'string') {
        return t('common:invalidValue');
      }
      return validationResolver({
        value: v,
        required,
        validate,
        formData: getValues,
        defaultRequiredError: t('common:requiredField'),
        defaultValidationError: t('common:invalidValue')
      });
    },
    shouldUnregister,
    disabled
  });

  const onChange = (v: string | number | null) => {
    if (v === field.value) {
      return;
    }

    field.onChange(v);
  };

  return (
    <CarrotNumberInput
      error={Boolean(error)}
      onChange={onChange}
      value={field.value}
      {...props}
    />
  );
};
