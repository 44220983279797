import { PropsWithChildren, ReactNode } from 'react';
import { tv } from '../../../utils/tv';
import { FormFieldErrorMessage } from '../parts/error-message';
import { FormFieldFeedback } from '../parts/feedback';
import { HelpTextContainer } from '../parts/help-text-container';
import { LabelContainer } from '../parts/label-container';

const style = tv({
  base: `
    flex
    flex-col
    gap-xxs
  `
});

export type FormFieldProps = PropsWithChildren<{
  errorMessage?: string;
  explanation?: string;
  feedback?: string;
  helpText?: string;
  label: ReactNode;
  maxLength?: number;
  optional?: string;
  valueLength?: number;
}>;

export const FormFieldComponent = ({
  errorMessage,
  explanation,
  feedback,
  helpText,
  label,
  maxLength,
  optional,
  valueLength,
  children
}: FormFieldProps) => {
  return (
    <div className={style()}>
      <LabelContainer
        label={label}
        optional={optional}
        explanation={explanation}
      />
      <HelpTextContainer
        helpText={helpText}
        maxLength={maxLength}
        valueLength={valueLength}
      />

      {children}

      {feedback && <FormFieldFeedback>{feedback}</FormFieldFeedback>}
      {errorMessage && (
        <FormFieldErrorMessage>{errorMessage}</FormFieldErrorMessage>
      )}
    </div>
  );
};
