import { ForwardedRef, forwardRef } from 'react';
import { tv } from '../../../utils/tv';
import { ButtonComponent, ButtonComponentProps } from '../components/as-button';
import { Icon } from '../../../theme/icon';

export type CalloutToggleType = 'alert' | 'question';

const style = tv({
  base: `
    rounded-full
    !p-0
    text-black

    hover:text-secondary
  `
});

type Props = Omit<
  ButtonComponentProps,
  'styles' | 'className' | 'as' | 'type'
> & {
  open?: boolean;
  type: CalloutToggleType;
};

const Component = (
  { open, type, ...props }: Props,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const icon = open
    ? 'showLessInfo'
    : type === 'alert'
      ? 'alert'
      : 'showMoreInfo';

  return (
    <ButtonComponent
      {...props}
      className={style()}
      buttonRef={ref}
      variant="borderless"
      size="small"
      alignment="left"
    >
      <Icon icon={icon} size="medium" />
    </ButtonComponent>
  );
};

export const CalloutToggle = forwardRef(Component);
