import { useId, useState } from 'react';
import { nbsp } from '../../utils/nbsp';

type Args<T extends unknown[]> = {
  onChange?: (value: T) => void;
  displayValue?: (value?: T[number]) => string | undefined;
};

export const useComboboxState = <T extends unknown[]>({
  onChange,
  displayValue
}: Args<T>) => {
  const inputId = useId();
  const [query, setQuery] = useState('');

  const valueToString = (value?: T[number]) => {
    return displayValue?.(value) ?? value?.toString() ?? nbsp;
  };

  const resetQuery = () => {
    setQuery('');
  };

  const onChangeInternal = (value: T) => {
    resetQuery();
    onChange?.(value);
  };

  return {
    inputId,
    query,
    setQuery,
    valueToString,
    resetQuery,
    onChangeInternal
  };
};
