import { ButtonHTMLAttributes, PropsWithChildren, Ref } from 'react';
import { twMerge } from 'tailwind-merge';
import { Simplify } from 'type-fest';

import { buttonStyle, ButtonStyles } from '../styles';

type ButtonProps = Simplify<ButtonHTMLAttributes<HTMLButtonElement>>;

export type ButtonComponentProps = Simplify<
  PropsWithChildren<
    ButtonStyles & {
      as?: 'button';
      buttonRef?: Ref<HTMLButtonElement>;
      type?: ButtonProps['type'];
      className?: ButtonProps['className'];
      disabled?: ButtonProps['disabled'];
      tabIndex?: ButtonProps['tabIndex'];
      onClick?: ButtonProps['onClick'];
    }
  >
>;

export const ButtonComponent = ({
  alignment,
  fillParent,
  focused,
  size,
  variant,

  // react props
  type,
  buttonRef,
  className,
  children,
  disabled,
  tabIndex,
  onClick
}: ButtonComponentProps) => {
  return (
    <button
      type={type ?? 'button'}
      ref={buttonRef}
      className={twMerge(
        buttonStyle({
          alignment,
          fillParent,
          focused,
          size,
          variant
        }),
        className
      )}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};
