export enum FeatureFlags {
  'ADD_CUSTOMER_FLOW' = 'ADD_CUSTOMER_FLOW'
}

export const hasUserFeatureFlag = (
  featureFlag: FeatureFlags,
  userFeatureFlags: string[] | undefined
) => {
  return userFeatureFlags && userFeatureFlags.includes(featureFlag);
};
