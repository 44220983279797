/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getConsumers } from 'api';
import { Consumer } from 'types/Consumer';
import REQ, { ReqType } from 'utils/REQ';

export const fetchConsumers = createAsyncThunk(
  'producers/fetchConsumers',
  async (options: { producerId: string | undefined }) => {
    const consumers = await getConsumers(options);
    return consumers;
  }
);

type ConsumersState = {
  req: ReqType;
  items: Consumer[];
};

const initialState: ConsumersState = { req: REQ.INIT, items: [] };

const consumersSlice = createSlice({
  name: 'consumers',
  initialState,
  reducers: {
    clearConsumers() {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchConsumers.fulfilled,
        (state, action: PayloadAction<Consumer[]>) => {
          state.req = REQ.SUCCESS;
          state.items = action.payload;
        }
      )
      .addCase(fetchConsumers.pending, state => {
        state.req = REQ.PENDING;
        state.items = [];
      })
      .addCase(fetchConsumers.rejected, state => {
        state.req = REQ.ERROR;
        state.items = [];
      });
  }
});

export default consumersSlice.reducer;

export const { clearConsumers } = consumersSlice.actions;
