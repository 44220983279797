import { useTranslation } from '@dagens/frontend-i18n';
import { capitalize } from 'lodash';

import DeliveryTypeTag from '../../../_common/components/tags/DeliveryTypeTag';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { useMediaQuery } from '../../../_common/hooks/useMediaQueries';
import { DeliveryType } from '../../../types/Logistics';
import { joinArrayOptions } from '../../../utils/texts';
import { useProducerPlans } from '../../hooks/use-producer-plans';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import Icon from '_common/components/utils/Icon';
import { ButtonAnchor } from '_common/components/button/Button.style';

type LogisticsInfoProps = {
  distributionAreaName: string;
  deliveryType: DeliveryType;
  hubName?: string;
  toInfoLink?: string;
  dropOffDays: string[];
  dropOffDeadline?: string;
};

type Route = {
  _type: string;
  partner: {
    _ref: string;
  };
};

const isProducerToHubTransport = (
  route: Route[] = [],
  producerId: string | undefined
) => {
  return route[0]._type === 'transport' && route[0].partner._ref === producerId;
};

const getRouteInfo = (
  logisticsRoute: any,
  producerId: string | undefined
): LogisticsInfoProps => {
  const { t } = useTranslation();
  const { distributionAreaName, route } = logisticsRoute;

  if (isProducerToHubTransport(route, producerId)) {
    return {
      distributionAreaName,
      deliveryType: DeliveryType.DAGENS_DELIVERY,
      hubName: route[0].toName,
      toInfoLink: route[0].toInfoLink,
      dropOffDeadline: route[0].toDeadline,
      dropOffDays: route[0]?.pickupDays.map((d: string) => {
        return capitalize(t(`calendar:days.${d}`, { count: 2 }));
      })
    };
  }

  return {
    distributionAreaName,
    deliveryType: DeliveryType.SELF_DELIVERY,
    dropOffDays: route[0]?.pickupDays.map((d: string) => {
      return capitalize(t(`calendar:days.${d}`, { count: 2 }));
    })
  };
};

const getProducerLogisticsInfo = (
  logisticsRoutes: any[] | undefined,
  producerId: string | undefined
): LogisticsInfoProps[] => {
  if (!logisticsRoutes || logisticsRoutes.length < 1) return [];
  return [...logisticsRoutes]
    .sort((a, b) => {
      return a.distributionAreaName > b.distributionAreaName ? 1 : -1;
    })
    .map(route => {
      return getRouteInfo(route, producerId);
    });
};

const ProducerDeliveryRoutes = () => {
  const { t } = useTranslation();
  const mediaQuery = useMediaQuery();

  const {
    userId,
    logisticsRoutes,
    transactionCut,
    transactionCutForSelfDelivery
  } = useAppSelector(({ auth }) => {
    return {
      userId: auth._id,
      logisticsRoutes: auth.logisticsRoutes,
      transactionCut: auth.transactionCut as number,
      transactionCutForSelfDelivery:
        auth.transactionCutForSelfDelivery as number
    };
  });

  const { platformFee, deliveryFee } = useProducerPlans(
    transactionCut,
    transactionCutForSelfDelivery
  );

  const logisticsInfo: LogisticsInfoProps[] = getProducerLogisticsInfo(
    logisticsRoutes,
    userId
  );

  return (
    <>
      {logisticsInfo.map(
        ({
          distributionAreaName,
          deliveryType,
          hubName,
          toInfoLink,
          dropOffDays,
          dropOffDeadline
        }) => {
          return (
            <Container
              key={`${deliveryType}_${distributionAreaName}`}
              backgroundColour="white"
              borderColor="lightGrey"
              px="s"
              py="s"
              mb="xs"
            >
              <Container flex mb="s" gap="xs">
                <Typography variant="paragraphBold">
                  {t('producer:LogisticsInfo.header', { distributionAreaName })}
                </Typography>
                <DeliveryTypeTag deliveryType={deliveryType} />
              </Container>
              <Typography variant="paragraph" mb="s">
                {deliveryType === DeliveryType.SELF_DELIVERY
                  ? t('producer:LogisticsInfo.descriptionSelfDelivery')
                  : `${t('producer:LogisticsInfo.descriptionDagensDelivery', {
                      hubName
                    })} ${t('producer:DagensTakesCareOfDelivery')}`}
              </Typography>
              <Container
                flex
                direction={mediaQuery.tabletUp ? 'row' : 'column'}
                wrap="wrap"
                mb="xxs"
              >
                <Typography
                  variant="paragraphSmall"
                  as="span"
                  mr="xxs"
                  color="secondary"
                >
                  {deliveryType === DeliveryType.SELF_DELIVERY
                    ? t('producer:LogisticsInfo.deliveryDaysTitle')
                    : t('producer:LogisticsInfo.dropOffDaysAtTitle', {
                        hubName
                      })}
                  :
                </Typography>
                <Typography variant="paragraphSmall" as="span">
                  {deliveryType === DeliveryType.SELF_DELIVERY
                    ? joinArrayOptions(dropOffDays, t('common:and'))
                    : t('producer:LogisticsInfo.dropOffDaysAtValue', {
                        days: joinArrayOptions(dropOffDays, t('common:and')),
                        deadline: dropOffDeadline
                      })}
                </Typography>
              </Container>
              <Container
                flex
                direction={mediaQuery.tabletUp ? 'row' : 'column'}
                wrap="wrap"
              >
                <Typography
                  variant="paragraphSmall"
                  as="span"
                  mr="xxs"
                  color="secondary"
                >
                  {t('producer:LogisticsInfo.priceTitle')}:
                </Typography>
                <Typography variant="paragraphSmall" as="span">
                  {deliveryType === DeliveryType.SELF_DELIVERY
                    ? t('producer:LogisticsInfo.priceValueSelfDelivery', {
                        price: platformFee * 100
                      })
                    : t('producer:LogisticsInfo.priceValueDagensDelivery', {
                        price: deliveryFee * 100
                      })}
                </Typography>
              </Container>
              {toInfoLink && (
                <Container mt="xs">
                  <ButtonAnchor
                    variant="borderless"
                    href={toInfoLink}
                    target="_blank"
                  >
                    {t('producer:LogisticsInfo.seeDetailsAboutHubButtonText', {
                      hubName
                    })}
                    &nbsp;
                    <Icon icon="externalLinkSmall" fill="deepPurple" />
                  </ButtonAnchor>
                </Container>
              )}
            </Container>
          );
        }
      )}
    </>
  );
};

export default ProducerDeliveryRoutes;
