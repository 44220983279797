import { useTranslation } from '@dagens/frontend-i18n';
import Container from '../../../../carrot-deprecated/container';
import Typography from '../../../../carrot-deprecated/typography';
import { useProducerPlans } from '../../../hooks/use-producer-plans';
import { PricingPlan } from './PricingPlan';

type ProfilePlanAndPaymentSectionProps = {
  transactionCut: number;
  transactionCutForSelfDelivery: number;
};

export const PricingPlanList = ({
  transactionCut,
  transactionCutForSelfDelivery
}: ProfilePlanAndPaymentSectionProps) => {
  const { t } = useTranslation();
  const { platformFee, deliveryFee } = useProducerPlans(
    transactionCut,
    transactionCutForSelfDelivery
  );
  return (
    <Container
      flex
      width="full"
      alignItems="stretch"
      justify="flex-start"
      gap="xs"
      mb="l"
    >
      <PricingPlan
        cut={platformFee}
        title={t('producer:SystemFee')}
        description={
          <Typography variant="paragraphSmall">
            {t('producer:pricingPlan.appliesToAllOrders')}
          </Typography>
        }
        helpDescription={t('producer:pricingPlan.systemFeeDescription')}
      />
      <PricingPlan
        cut={deliveryFee}
        title={t('producer:DagensDeliveryLogisticsFee')}
        description={
          <>
            <Typography variant="paragraphSmallBold">
              {t('producer:pricingPlan.added')}{' '}
            </Typography>
            <Typography variant="paragraphSmall" as="span">
              {t('producer:pricingPlan.toOrdersDeliveredByDates')}
            </Typography>
          </>
        }
        helpDescription={t('producer:pricingPlan.dagensDeliveryFeeDescription')}
      />
    </Container>
  );
};
