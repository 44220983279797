import { PricingStructure } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useWatch } from '@dagens/frontend-forms';
import { PricingFormValues } from '..';
import { useTransactionCut } from '../hooks/use-transaction-cut';

type Props = {
  defaultPriceValue?: number | null;
};

// Displays the price breakdown based on the price and transaction cut
export const PricingFormPriceStructure = ({ defaultPriceValue }: Props) => {
  const { t } = useTranslation();
  const transactionCut = useTransactionCut();
  const priceValue = useWatch<PricingFormValues>({
    name: 'priceValue',
    defaultValue: defaultPriceValue
  }) as number | null;

  if (priceValue === null || isNaN(priceValue)) {
    return <PricingStructure.Fallback text={t('producer:productPriceFee')} />;
  }

  return (
    <div
      // To match the width of number input + select side-by-side
      style={{
        width: '264px'
      }}
    >
      <PricingStructure
        priceValue={priceValue}
        transactionCut={transactionCut}
        text={{
          serviceFee: value =>
            t('producer:ServiceFee', { transactionCutPercent: value }),
          serviceFeeAmount: value =>
            t('producer:ServiceFeeAmount', { serviceFee: value }),
          toCurrency: value => `${value.toFixed(2)} kr`,
          youGetExclVat: t('producer:YouGetExclVat')
        }}
      />
    </div>
  );
};
