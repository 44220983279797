import { useTranslation } from '@dagens/frontend-i18n';
import { AvailableLanguages, CountryCode, isCountry } from '@dagensmat/core';
import { useState } from 'react';

import { OnboardingHistoryKeys } from '../../../types/Producer';
import ProfileAboutSection from './ProfileAboutSection';
import ProfileLanguageSection from './ProfileLanguageSection';
import ProfileLogisticsSection from './ProfileLogisticsSection';
import ProfileMinimumOrderSection from './ProfileMinimumOrderSection';
import ProfileNotificationsSection from './ProfileNotificationsSection';
import ProfilePlanAndPaymentSection from './plan-and-payment-section';
import ProfileUnavailableSection from './ProfileUnavailableSection';
import ProfileUsersSection from './ProfileUsersSection';
import Typography from '@carrot-deprecated/typography';
import REQ, { ReqType } from 'utils/REQ';
import { postProducerUpdate } from 'api';
import { Page } from '@components/page';
import ProfileMyShop from '_producer/pages/profile/ProfileMyShop';
import ProfileEcologicSection from '_producer/pages/profile/ProfileEcologicSection';
import ProfileContactInfoSetcion from '_producer/pages/profile/ProfileContactInfoSetcion';
import { userUpdated } from '_common/reducers/auth';
import ProfileDataExport from '_common/pages/profile/DataExport';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import PageHeader from '@components/page-header/PageHeader';
import InContextOnboardingMessage from '_common/components/in-context-onboarding-message/InContextOnboardingMessage';

const ProfilePage = () => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const dispatch = useAppDispatch();
  const {
    name,
    userId,
    contactPerson,
    phone,
    image,
    dagensId,
    instagram,
    profileArea,
    profileBio,
    transactionCut,
    transactionCutForSelfDelivery,
    temporarilyUnavailableDates,
    roleLang,
    ecologicNumber,
    ecologicCodeNumber,
    organization,
    shopUrl,
    hideShop,
    handle,
    minimumOrderAmount,
    minimumOrderAmountEnforced,
    onboardingHistory
  } = useAppSelector(({ auth }) => {
    return {
      userId: auth._id,
      name: auth.name,
      contactPerson: auth.contactPerson,
      phone: auth.phone,
      image: auth.image,
      dagensId: auth.dagensId,
      instagram: auth.instagram,
      profileArea: auth.profileArea,
      profileBio: auth.profileBio,
      logisticsRoutes: auth.logisticsRoutes,
      transactionCut: auth.transactionCut as number,
      transactionCutForSelfDelivery:
        auth.transactionCutForSelfDelivery as number,
      temporarilyUnavailableDates: auth.temporarilyUnavailable,
      roleLang: auth.roleLang,
      ecologicNumber: auth.ecologicNumber,
      ecologicCodeNumber: auth.ecologicCodeNumber,
      organization: auth.organization,
      shopUrl: auth.shopUrl,
      hideShop: auth.hideShop,
      handle: auth.handle,
      minimumOrderAmount: auth.minimumOrderAmount,
      minimumOrderAmountEnforced: auth.minimumOrderAmountEnforced,
      onboardingHistory: auth.onboardingHistory
    };
  });

  const dispatchUserChange = (fields: {
    roleLang?: AvailableLanguages;
    contactPerson?: string;
    shopUrl?: string;
    hideShop?: boolean;
    handle?: string;
  }) => {
    dispatch(userUpdated(fields));
  };

  const onFieldsChange = async (fields: { roleLang: AvailableLanguages }) => {
    setReq(REQ.PENDING);
    dispatchUserChange(fields);

    const result = await postProducerUpdate({
      userId,
      ...fields
    });

    setReq(REQ.SUCCESS);
    return result;
  };
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader headerText={t('producer:MyPage')} />
      <InContextOnboardingMessage
        message={t('producer:profileGetToKnow')}
        showStep={
          !onboardingHistory?.[OnboardingHistoryKeys.filledOutAboutInfo]
        }
      />

      <Typography
        variant="paragraphBold"
        as="h2"
        color="secondary"
        mt="l"
        mb="xs"
      >
        {t('producer:producerNamePresentationProfileHeading', {
          producerName: name
        })}
      </Typography>
      <ProfileAboutSection
        headerText={t('producer:profileAbout')}
        name={name}
        profileArea={profileArea}
        instagram={instagram}
        profileBio={profileBio}
        onFieldsChange={onFieldsChange}
        profileImage={image}
        imageUpdated={dispatchUserChange}
        producerId={userId}
      />
      <ProfileContactInfoSetcion
        headerText={t('common:ContactInformation')}
        userId={userId}
        onChange={dispatchUserChange}
        contactPerson={contactPerson}
        phone={phone}
      />
      {organization && isCountry(organization, CountryCode.DK) && (
        <ProfileEcologicSection
          headerText={t('producer:EcologicLabeling')}
          ecologicNumber={ecologicNumber}
          ecologicCodeNumber={ecologicCodeNumber}
          onFieldsChange={onFieldsChange}
        />
      )}
      <ProfileMyShop
        headerText={t('producer:profileMyShop')}
        userId={userId}
        onChange={dispatchUserChange}
        shopUrl={shopUrl}
        dagensId={dagensId}
        hideShop={hideShop}
        handle={handle}
      />

      <Typography
        variant="paragraphBold"
        as="h2"
        color="secondary"
        mt="jumbo"
        mb="xs"
      >
        {t('producer:tradeAndAvailabilityProfileHeading')}
      </Typography>
      <ProfileMinimumOrderSection
        headerText={t('producer:MinimumOrderPageHeader')}
        minimumOrderAmount={minimumOrderAmount}
        minimumOrderAmountEnforced={minimumOrderAmountEnforced}
      />
      <ProfileLogisticsSection headerText={t('producer:profileLogistics')} />
      <ProfileDataExport headerText={t('common:TradeInsightAndData')} />
      <ProfileUnavailableSection
        headerText={t('producer:profileUnavailable')}
        dates={temporarilyUnavailableDates}
        onFieldsChange={onFieldsChange}
        isSaving={req === REQ.PENDING}
      />
      <Typography
        variant="paragraphBold"
        as="h2"
        color="secondary"
        mt="jumbo"
        mb="xs"
      >
        {t('producer:accountProfileHeading')}
      </Typography>
      <ProfilePlanAndPaymentSection
        headerText={t('common:PricingPlanAndPaymentHeader')}
        transactionCut={transactionCut}
        transactionCutForSelfDelivery={transactionCutForSelfDelivery}
      />
      <ProfileUsersSection headerText={t('common:Users')} />
      <ProfileNotificationsSection headerText={t('common:Notifications')} />
      <ProfileLanguageSection
        headerText={t('common:Language')}
        onFieldsChange={onFieldsChange}
        roleLang={roleLang}
      />
    </Page>
  );
};

export default ProfilePage;
